import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import {
  ISalesDashboardData,
  SalesDashboardService
} from './sales-dashboard.service';

interface IFormattedSaleDashboardData extends ISalesDashboardData {
  formattedTotalSalesCommission: string;
  formattedSalesTotalValue: string;
  revenuesByDay: {
    salesCount: number;
    salesTotalValue: number;
    formattedTotalValue: string;
    formattedComissionValue: string;
    valueCommission: number;
    day: string;
  }[];
}

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.css'],
})
export class SalesDashboardComponent implements OnInit {
  cabecalhoVisible = true;

  @Input() userProfile!: IUserProfileWithCSS | null;

  dashboardData: IFormattedSaleDashboardData | null = null;
  resellersOptions: {id: string, name: string}[] = [];

  monthFilter = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
  filterReseller: string | null = 'all';

  screenWidth: any;
  screenHeight: any;

  constructor(
    private salesDashboardService: SalesDashboardService,
    private modalService: NgbModal,
  ) {

    this.getScreenSize();

    this.fetchSalesDashboardData();

    this.salesDashboardService.getResellers().subscribe(({ body }) => {
      if(body) {
        this.resellersOptions = body.map((reseller) => ({
          id: reseller.idUser,
          name: `${reseller.personal.name} ${reseller.personal.lastName}`,
        }));
      }
    });
  }

  fetchSalesDashboardData() {
    const brl = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.salesDashboardService.getSalesDashboardData({
      monthFilter: this.monthFilter,
      ...(this.filterReseller && this.filterReseller !== 'all' && { userId: this.filterReseller })
    }).subscribe(({ body }) => {
      if (body) {
        this.dashboardData = {
          ...body,
          formattedTotalSalesCommission: brl.format(body.totalSalesCommission),
          formattedSalesTotalValue: brl.format(body.salesTotalValue),
          revenuesByDay: body.revenuesByDay.map((revenue) => ({
            ...revenue,
            formattedTotalValue: brl.format(revenue.salesTotalValue),
            formattedComissionValue: brl.format(revenue.valueCommission),
          })),
        };
      }
    });
  }

  ngOnInit(): void {
    return;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
}
