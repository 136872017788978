import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { IDocumentUser } from '../model/document-user.model';

export interface ISalesDashboardData {
  totalSalesCommission: number;
  salesCount: number;
  salesTotalValue: number;
  revenuesByDay: { day: string; salesCount: number; salesTotalValue: number; valueCommission: number }[];
}

export interface ISalesDashboardParams {
  monthFilter: string;
  userId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SalesDashboardService {

  baseUrl = environment.profileUrl;
  baseAdmUrl = environment.administradorUrl;

  constructor(protected http: HttpClient, protected jwtHelper: JwtHelperService, protected router: Router) {
}
  getSalesDashboardData(params: ISalesDashboardParams): Observable<HttpResponse<ISalesDashboardData>> {
    return this.http.get<ISalesDashboardData>(`${this.baseAdmUrl}/sales-dashboard`, {
      observe: 'response',
      params: {
        monthFilter: params.monthFilter,
        ...(params.userId && {userIdFilter: params.userId})
      }
    });
  }

  getResellers(): Observable<HttpResponse<IDocumentUser[]>> {
    return this.http.get<IDocumentUser[]>(`${this.baseAdmUrl}/resellers`, {
      observe: 'response',
    });
  }
}
