<div class="body-div">
    <div class="content-div">
        <span class="title-text">Contas Sluper</span>
        <form>
            <div class="input-group inputs">
                <div class="input-group-prepend">
                    <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-search"></i></span>
                </div>
                <input type="text" class="form-control input-text" id="nome" name="nome" [(ngModel)]="nome">
            </div>
            <table class="table table-borderless margin-top">
                <thead>
                    <tr>
                        <th scope="col">Nome completo</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Data de ativação</th>
                        <th scope="col" class="center-text">Ativar / desativar</th>
                        <th scope="col" class="center-text">Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr *ngFor="let license of licensas; let i = index"> -->
                    <tr>
                        <td><button class="icon-button margin-right"><i
                                    class="bi bi-file-person person-icon"></i></button>Vinicius
                            Vasques</td>
                        <td>vinicius.silva@grupomult.com.br</td>
                        <td>07/09/2023</td>
                        <td class="center-text">
                            <div class="form-check form-switch">
                                <input type="checkbox" role="switch" class="form-check-input">
                            </div>
                        </td>
                        <td class="center-text"><button class="icon-button"><i
                                    class="bi bi-trash delete-button"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </form>
        <div class="novo-usuario-div">
            <button class="novo-usuario-button"><i class="bi bi-file-person"></i>Novo usuário</button>
        </div>
    </div>
</div>