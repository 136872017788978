<div class="base-degade">
    <div class="ellipse-9"></div>
    <div class="ellipse-12"></div>
    <div class="ellipse-11"></div>
    <div class="modal-close">
        <button type="button" class="btn" (click)="onCloseClick()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <mask id="mask0_1845_4577" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1845_4577)">
                    <path
                        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                        fill="white" />
                </g>
            </svg>
        </button>
    </div>
    <div class="content">
        <img src="/assets/img/ilustracao.svg" alt="Ilustração" class="ilustracao" *ngIf="screenWidth > 550">
        <div class="div-chave">
            <div class="login-text-div">
                <h1 class="login-text" translate>chaveAcesso.login</h1>
            </div>
            <div class="descricao-text-div" *ngIf="screenWidth > 550">
                <p class="descricao-text" translate>chaveAcesso.informe</p>
            </div>
            <div class="text-box">
                <div style="width: 100%; display: flex; flex-direction: column; gap: 8px;">
                    <label for="chave" class="label-text">Chave de Acesso</label>
                    <input type="text" name="chave" id="chave" class="chave-input" [formControl]="chaveAcesso">
                    <p class="descricao-text" *ngIf="screenWidth < 550" translate>chaveAcesso.informe</p>
                </div>
                <button type="button" class="btn entrar-button" (click)="onEntrarClick()">
                    <span class=" entrar-text" translate>botaoEntrar</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <mask id="mask0_1914_1637" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="25" height="24">
                            <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_1914_1637)">
                            <path
                                d="M11.8 19.3C11.6167 19.1167 11.5207 18.8834 11.512 18.6C11.504 18.3167 11.5917 18.0834 11.775 17.9L16.675 13H5.5C5.21667 13 4.979 12.904 4.787 12.712C4.59567 12.5207 4.5 12.2834 4.5 12C4.5 11.7167 4.59567 11.479 4.787 11.287C4.979 11.0957 5.21667 11 5.5 11H16.675L11.775 6.10005C11.5917 5.91672 11.504 5.68338 11.512 5.40005C11.5207 5.11672 11.6167 4.88338 11.8 4.70005C11.9833 4.51672 12.2167 4.42505 12.5 4.42505C12.7833 4.42505 13.0167 4.51672 13.2 4.70005L19.8 11.3C19.9 11.3834 19.971 11.4874 20.013 11.612C20.0543 11.7374 20.075 11.8667 20.075 12C20.075 12.1334 20.0543 12.2584 20.013 12.375C19.971 12.4917 19.9 12.6 19.8 12.7L13.2 19.3C13.0167 19.4834 12.7833 19.575 12.5 19.575C12.2167 19.575 11.9833 19.4834 11.8 19.3Z"
                                fill="white" />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>