import { Component, Input } from '@angular/core';
// Import your service if you're making an API call
// import { YourService } from './your.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent {
  @Input() bgTransparent = true;

  constructor() {
    return;
  }
}
