import { Component } from '@angular/core';
import { IDocumentLicense } from '../model/document-license.model';

@Component({
  selector: 'app-contas-ativas',
  templateUrl: './contas-ativas.component.html',
  styleUrls: ['./contas-ativas.component.css']
})
export class ContasAtivasComponent {

  nome: string = '';
  licensas: IDocumentLicense[] = [];
}
