<div class="body-div">
  <div class="title-container">
    <h1 class="title" translate>
      menu.dashboard
    </h1>
  </div>

  <div class="content-container">
    <section class="dashboard-section"
      [style]="!adminPlan.adminPlanLimits.dashViews ? 'filter: blur(6px); -webkit-filter: blur(8px); z-index: 1;' : ''">
      <div class="input-group inputs search-input-container">
        <div class="input-group-prepend">
          <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-search"></i></span>
        </div>

        <input [disabled]="!adminPlan.adminPlanLimits.dashViews" class="form-control filters-input"
          [ngClass]="filteredOptions.length > 0 ? 'opened-input': ''" id="filtroAtivo" name="filtroAtivo"
          [(ngModel)]="filterActive" (input)="filterValues($event)" placeholder="Digite para filtrar">

        <div *ngIf="filteredOptions.length > 0" class="autocomplete-options">
          <div *ngFor="let option of filteredOptions" (click)="selectOption(option)" class="option">
            <span class="optinUserName">{{ option.name }}</span>
            <span class="optionUserEmail" *ngIf="option.idUser !== ''"> {{ option.email }}</span>
          </div>
        </div>
      </div>

      <div class="dashboard-content">
        <app-dashboard-card class="card-container" [dashboardCardData]="{
        title: 'Visitas ao perfil',
        icon: 'bi-person-check',
        color: '#81bd86',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.profileAccessCount ? dashboardData?.profileAccessCount?.toString() : '0'
      }" (click)="openProfileAccessedRankingModal()">
        </app-dashboard-card>

        <app-dashboard-card class="card-container"
          [canOpenList]="adminPlan.adminPlanLimits.dashLinks !== undefined && adminPlan.adminPlanLimits.dashLinks"
          [dashboardCardData]="{
        icon: 'bi-hdd-rack',
        title: 'Links acessados',
        color: '#e7c465',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.acessedLinksCount ? dashboardData?.acessedLinksCount?.toString() : '0',
        links: dashboardData?.topAccessedLinks
      }" variant="list-links">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
        icon: 'bi-credit-card-2-front',
        title: 'Cartōes gravados',
        color: '#65b8e7',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.generatedCardsCount ? dashboardData?.generatedCardsCount?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
        icon: 'bi-qr-code',
        title: 'Licenças geradas',
        color: '#65e7a3',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.generatedLicenses ? dashboardData?.generatedLicenses?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
        icon: 'bi-file-person',
        title: 'Licenças vinculadas',
        color: '#6593e7',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.linkedLicenses ? dashboardData?.linkedLicenses?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
        icon: 'bi-unlock',
        title: 'Licenças disponíveis',
        color: '#8ce765',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.availableLicenses ? dashboardData?.availableLicenses?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
        icon: 'bi-patch-check-fill',
        title: 'Licenças ativas',
        color: '#e78e65',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.enabledLicensesCount ? dashboardData?.enabledLicensesCount?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
        icon: 'bi-dash-square-fill',
        title: 'Licenças inativas',
        color: '#e76565',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.unavailableLicenses ? dashboardData?.unavailableLicenses?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
        icon: 'bi-stripe',
        title: 'Licenças Stripe',
        color: '#6772e5',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.stripeLicensesCount ? dashboardData?.stripeLicensesCount?.toString() : '0'
      }">
        </app-dashboard-card>

        <app-dashboard-card class="card-container" [dashboardCardData]="{
        icon: 'bi-bullseye',
        title: 'Redes acessadas',
        color: '#8c65e7',
        value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardData?.socialNetworkAcessedCount ? dashboardData?.socialNetworkAcessedCount?.toString() : '0',
        links: dashboardData?.topAccessedSocialNetworks
      }" variant="list-social-networks"
          [canOpenList]="adminPlan.adminPlanLimits.dashLinks !== undefined && adminPlan.adminPlanLimits.dashLinks">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
            icon: 'bi-calendar-range',
            title: 'Eventos',
            color: '#81bd86',
            value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardEventAdmin?.events ? dashboardEventAdmin?.events?.toString() : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
          icon: 'bi-people',
          title: 'Conexões',
          color: '#81bd86',
          value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardEventAdmin?.connections ? dashboardEventAdmin?.connections?.toString() : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
          icon: 'bi-person-exclamation',
          title: 'Indicações',
          color: '#81bd86',
          value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardEventAdmin?.indications ? dashboardEventAdmin?.indications?.toString() : '0'
          }">
        </app-dashboard-card>

        <app-dashboard-card *ngIf="userProfile?.documentUser?.role == 'adm'" class="card-container" [dashboardCardData]="{
          icon: 'bi-send-check',
          title: 'Comunidades',
          color: '#81bd86',
          value: !adminPlan.adminPlanLimits.dashViews ? '1383' : dashboardEventAdmin?.communities ? dashboardEventAdmin?.communities?.toString() : '0'
          }">
        </app-dashboard-card>

      </div>

      <div class="graphs-container">
        <div class="access-graphs-box">
          <span class="access-graph-title">Métricas de acesso por semana</span>

          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
            class="no-dash-graph-access-button"
            *ngIf="adminPlan.adminPlanLimits.dashAccessGraph !== undefined && !adminPlan.adminPlanLimits.dashAccessGraph">
            <i class="bi bi-lock-fill"></i>

            <div class="no-dash-graph-messages">
              Para visualizar o gráfico de acesso,
              <a target="_blank"
                href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
                upgrade do seu plano</a>
            </div>
          </a>

          <div class="chart-container" [ngClass]="!adminPlan.adminPlanLimits.dashAccessGraph ? 'hidden' : ''">
            <apx-chart style="width: 100%;" [series]="chartOptions.series" [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis" [stroke]="chartOptions.stroke" [tooltip]="chartOptions.tooltip"
              [dataLabels]="chartOptions.dataLabels"></apx-chart>
          </div>
        </div>
      </div>

      <div class="ultima-atualizacao-div">
        <span *ngIf="dashboardData?.updateDate" class="ultima-atualizacao-text">última atualização: {{
          dashboardData?.updateDate | date: 'dd/MM/yyyy - HH:mm'}}</span>
      </div>
    </section>

    <div class="no-access-container" *ngIf="!adminPlan.adminPlanLimits.dashViews">
      <div class="no-access-content">
        <span class="no-access-text" translate>Seu plano atual não permite acesso ao dashboard.</span>
        <img src="/assets/img/undraw_access_denied_re_awnf.svg" style="width: 200px" alt="No access"
          class="no-access-img" />

        <section class="call-to-action">
          <span class="cta-label"> Clique abaixo para escolher seu plano ideal e começar a transformação. </span>

          <a href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal" target="_blank"
            class="update-plan-button">
            Atualizar Plano
          </a>
        </section>
      </div>
    </div>
  </div>
</div>
