<div class="modal-container">
  <div class="header">
    <h2 class="modal-title">Ranking de perfis acessados</h2>

    <button class="close-button" (click)="closeModal()">
      <i class="bi bi-x"></i>
    </button>
  </div>

  <div class="ranking-list">
    <div class="profile-card" *ngFor="let profile of this.profiles" [style.background-image]="getBackgroundImg(profile?.userProfile?.uriImageBackground)" [style.background]="getBackground(profile.userProfile, profile.userProfile.uriImageBackground)">
      <img class="profile-img" src={{profile.userProfile.uriImageProfile}} alt="">

      <div class="user-basic-info">
        <span
          [style.color]="profile.userProfileCSS.css.corFontePerfil"
          [style.font-family]="profile.userProfileCSS.css.fontePerfil"
          [style.font-weight]="profile.userProfileCSS.css.negritoPerfil ? 'bold' : undefined"
          [style.font-style]="profile.userProfileCSS.css.italicoPerfil ? 'italic' : undefined"
         class="name-label"
        >
         {{profile.userProfile.name}}
        </span>
        <span
          [style.color]="profile.userProfileCSS.css.corFontePerfil"
          [style.font-family]="profile.userProfileCSS.css.fontePerfil"
          [style.font-weight]="profile.userProfileCSS.css.negritoPerfil ? 'bold' : undefined"
          [style.font-style]="profile.userProfileCSS.css.italicoPerfil ? 'italic' : undefined"
          class="description-label"
        >
          {{profile.userProfile.bio}}
        </span>

        <span class="access-count" >
          {{profile.count}} acessos
        </span>
      </div>
    </div>
  </div>
</div>
