<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-header">
  <span class="title">Criar Promoçāo</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>

<div class="modal-body content">
  <form action="" class="form">
    <div class="form-row">
      <div class="input-container">
        <label for="promoName" class="labels-text">Nome da promoçāo</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-receipt"></i></span>
          </div>

          <input type="text" class="form-control input-text" id="promoName" name="promoName"
            [(ngModel)]="promo.promoName">
        </div>
      </div>

      <div class="input-container">
        <label for="promoDesc" class="labels-text">Descriçāo da promoçāo</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-receipt"></i></span>
          </div>

          <input type="text" class="form-control input-text" id="promoDesc" name="promoDesc"
            [(ngModel)]="promo.promoDescription">
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="input-container">
        <label for="frequency" class="labels-text">Frequencia</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-calendar-range"></i></span>
          </div>

          <select name="frequency" id="frequency" class="form-select input-text" [(ngModel)]="promo.frequency">
            <option *ngFor="let option of promoFrequenciesOptions" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>

      <div class="input-container">
        <label for="quantity" class="labels-text">Quantidade</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-123"></i></span>
          </div>

          <input type="number" name="quantity" id="quantity" class="form-control input-text"
            [(ngModel)]="promo.quantity" />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="input-container">
        <label for="startDate" class="labels-text">Data de início</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-calendar"></i></span>
          </div>

          <input class="form-control input-text" id="startDate" name="startDate" placeholder="dd/mm/yyyy" ngbDatepicker
            #d2="ngbDatepicker" [(ngModel)]="promo.startDate" type="date">
        </div>
      </div>

      <div class="input-container">
        <label for="endDate" class="labels-text">Data fim</label>

        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-calendar"></i></span>
          </div>

          <input class="form-control input-text" id="endDate" name="endDate" placeholder="dd/mm/yyyy" ngbDatepicker
            #d3="ngbDatepicker" [(ngModel)]="promo.endDate" type="date">
        </div>
      </div>
    </div>

    <div class="participants-container">
      <div class="participants-heading">
        <span class="participants-title">
          Lista de participantes
        </span>

        <div class="participant-selector-container">
          <div class="input-group inputs">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-person-add"></i></span>
            </div>

            <select name="user" id="user" class="form-select input-text" [(ngModel)]="selectedUser"
              (change)="selectUser($event)">
              <option value="">Adicionar usuário</option>
              <option *ngFor="let option of userOptions" [value]="option.idUser" class="option-item">
                {{ option.name}} - {{ option.email }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="list-container">
        <div *ngIf="participants.length == 0" class="any-users-selected">
          <span class="any-users-selected-text">
            Nenhum usuário selecionado
          </span>
        </div>

        <div class="participant-item" *ngFor="let participant of participants">
          <div class="participant-info">
            <span class="participant-name">{{participant.name}}</span>

            <span class="participant-email">
              {{participant.email}}
            </span>
          </div>

          <button class="remove-participant-button" (click)="removeUser(participant.id)">
            <i class="bi bi-x"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="buttons-container">
      <button class="btn btn-secondary" (click)="onCloseClick()">Cancelar</button>
      <button class="btn btn-primary" (click)="savePromo()">Salvar</button>
    </div>
  </form>
</div>

<div class="footer">
</div>