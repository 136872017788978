import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';

export interface UserAdminPlan {
  planName: string;
  adminPlanLimits : {
    dashCard: boolean;
    dashViews: boolean;
    dashTopProfileAccess: boolean;
    templateQuantity: number;
    dashLinkedlicenses: boolean;
    dashGeneratedLicenses: boolean;
    dashAccessGraph: boolean;
    dashLinks: boolean;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdmService {

  baseUrl = environment.profileUrl;
  baseAdmUrl = environment.administradorUrl;

  constructor(
    protected http: HttpClient,
    protected jwtHelper: JwtHelperService,
    protected router: Router
  ) { }

  userById(idUser: string | null): Observable<HttpResponse<IUserProfileWithCSS>> {
    if (idUser == null) {
      const token: string | null = localStorage.getItem('authToken');
      if (token) {
        const decodedToken = this.jwtHelper.decodeToken(token);
        const userId = decodedToken.idUser;

        idUser = userId;
      } else {
        this.router.navigate(['']);
        return throwError('Usuário não autenticado');
      }
    }
    return this.http.get<IUserProfileWithCSS>(`${this.baseUrl}/userById/${idUser}`, { observe: 'response' });
  }

  getAdmPlan(): Observable<HttpResponse<UserAdminPlan>> {
    return this.http.get<UserAdminPlan>(
      `${this.baseAdmUrl}/plan`,
      { observe: 'response' },
    );
  }
}
