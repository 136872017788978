<div class="custom-mb"></div>
<div class="template-creation-container">
  <section class="form-section">
    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'background'" (click)="toggleSection('background')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilo da sua aplicação!</h2>
              <span class="form-box-subtitle">Customize as imagens de acordo com a sua preferência</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="background-accordition-body">
            <ng-template>
              <div class="background-custom-container">
                <div class="background-option-container" (click)="changeBackgroundType('image')"
                  [style]="templateBgType === 'image' ? 'background-color: #fff;' : 'background: transparent'">
                  <div class="background-option-title">
                    <label for="image-background-enabled" class="image-background-enabled-label">
                      Logo do cabeçalho
                    </label>
                  </div>

                  <div class="background-options-content background-image-option-container">
                    <button class="background-image-selector"
                      (click)="openUploadTemplateImages('background')"
                      [style]="templateCss.cardBackgroundImageUrl ? 'background-image: url(' + templateCss.cardBackgroundImageUrl + ')' : ''">
                      <span class="background-image-selector-label" *ngIf="!templateCss.cardBackgroundImageUrl">
                        <i class="bi bi-file-image"></i>

                        Clique aqui para selecionar uma imagem para o fundo
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="buttons-container">
  <button class="save-button" (click)="saveTemplate()">
    Salvar
  </button>
</div>

<ng-template #templateBgImgSelctionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>meuSluper.modalImagem.titulo</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body file-selection-container">
    <input type="file" (change)="templateBgImageChanged($event)" accept="image/*" />

    <ng-container>
      <span class="image-scale-orientations">
        Tamanho ideal da imagem: 124x45
      </span>

      <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="false" [aspectRatio]="2"
        format="jpeg" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="1920"
        [cropperMaxHeight]="1080"></image-cropper>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmTemplateImageCropped(croppedBgImage)" translate>
      meuSluper.modalImagem.confirmar
    </button>
  </div>
</ng-template>
