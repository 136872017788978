export interface RedeSocial {
  name: string;
  value: string;
  placeHolder: string;
  icon: string;
}

export const LISTA_REDES: RedeSocial[] = [
  {
    name: 'Facebook',
    value: 'facebook',
    placeHolder: 'https://www.facebook.com/meu_perfil',
    icon: 'bi-facebook',
  },
  {
    name: 'Instagram',
    value: 'instagram',
    placeHolder: 'https://www.instagram.com/meu_perfil/',
    icon: 'bi-instagram',
  },
  {
    name: 'Linkedin',
    value: 'linkedin',
    placeHolder: 'https://www.linkedin.com/in/meu_perfil',
    icon: 'bi-linkedin',
  },
  {
    name: 'Pinterest',
    value: 'pinterest',
    placeHolder: 'https://br.pinterest.com/meu_perfil/',
    icon: 'bi-pinterest',
  },
  {
    name: 'Telegram',
    value: 'telegram',
    placeHolder: 'https://t.me/meu_nome_usuario',
    icon: 'bi-telegram',
  },
  {
    name: 'Tik Tok',
    value: 'tik tok',
    placeHolder: 'https://www.tiktok.com/@meu_perfil',
    icon: 'bi-tiktok',
  },
  {
    name: 'Twitch',
    value: 'twitch',
    placeHolder: 'https://www.twitch.tv/meu_canal',
    icon: 'bi-twitch',
  },
  {
    name: 'Twitter',
    value: 'twitter',
    placeHolder: 'https://twitter.com/meu_perfil',
    icon: 'bi-twitter-x',
  },
  {
    name: 'WhatsApp',
    value: 'wa',
    placeHolder: 'https://wa.me/+55031999999999',
    icon: 'bi-whatsapp',
  },
  {
    name: 'YouTube',
    value: 'youtube',
    placeHolder: 'https://www.youtube.com/channel/meu_canal',
    icon: 'bi-youTube',
  },
];
