import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { IDashboardAdmin } from '../model/dashboard.model';

export interface IDashboardData {
  acessedLinksCount: number;
  profileAccessCount: number;
  socialNetworkAcessedCount: number;
  generatedCardsCount: number;
  enabledLicensesCount: number;
  stripeLicensesCount: number;
  unavailableLicenses: number;
  availableLicenses: number;
  generatedLicenses: number;
  profileAccessByWeeks: number[];
  linksClickedByWeeks: number[];
  socialNetworkByWeeks: number[];
  linkedLicenses: number;
  profileAccessCountList: {
    count?: number;
    idUser?: string;
    userProfile?: {
      bio?: string;
      name?: string;
      uriImageBackground?: string;
      uriImageProfile?: string;
    };
    userProfileCSS?: {
      createDate?: string;
      css?: string;
    };
  }[];
  topAccessedSocialNetworks: {
    url: string;
    count: number;
  }[];
  topAccessedLinks: {
    url: string;
    count: number;
  }[];
  updateDate?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseUrl = environment.profileUrl;
  baseAdmUrl = environment.administradorUrl;
  eventsApiUrl = environment.eventsApiUrl;

  constructor(protected http: HttpClient, protected jwtHelper: JwtHelperService, protected router: Router) {
}

  getDashboardData(iduser?:string): Observable<HttpResponse<IDashboardData>> {
    let uri = `${this.baseAdmUrl}/dashboard/v2`;
    if (iduser !== null && iduser !== undefined && iduser !== '') {
      uri = uri + '?idUser=' + iduser;
    }
    return this.http.get<IDashboardData>(uri, { observe: 'response' });
  }

  userById(idUser: string): Observable<IUserProfileWithCSS> {
    return this.http.get<IUserProfileWithCSS>(`${this.baseUrl}/userById/${idUser}`).pipe(
      catchError(() => of({} as IUserProfileWithCSS))
    );
  }

  findAllUsersDashboard(): Observable<HttpResponse<{ idUser: string; name: string; email: string }[]>> {
    const url = `${this.baseAdmUrl}/allUsers`;
    return this.http.get<{ idUser: string; name: string; email: string }[]>(url, { observe: 'response' });
  }

  getEventDashboadAdmin(): Observable<HttpResponse<IDashboardAdmin>> {
    return this.http.get<IDashboardAdmin>(
      `${this.eventsApiUrl}/dashboadAdmin`,
      { observe: 'response' },
    );
  }
}
