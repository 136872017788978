import { Observable, catchError, map, of, tap } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ILoginResponse } from '../model/login-response.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrlSeguranca = environment.segurancaUrl;
  baseUrlAdmin = environment.administradorUrl;

  constructor(protected http: HttpClient) { }

  criaPrimeiroAcesso(license: string | null, verificationKey: string | null, email: string | null): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      license: license,
      verificationKey: verificationKey,
      email: email
    }

    return this.http.post<string>(
      `${this.baseUrlAdmin}/firstAccess`,
      body,
      { headers: headers, responseType: 'text' as 'json' }
    ).pipe(
      map(response => response !== null && response !== ''),
      catchError(() => of(false))
    );
  }

  validateVerificationKey(verificationKey: string | null): Observable<boolean> {

    if (verificationKey == null) {
      return of(false);
    }

    const queryParams = {
      verificationKey: verificationKey
    };

    return this.http.get<string>(
      `${this.baseUrlAdmin}/verificationKey`,
      { params: queryParams, responseType: 'text' as 'json' }
    ).pipe(
      map(response => response.trim() === 'valid'),
      catchError(() => of(false))
    );
  }

  validarLicensa(license: string): Observable<boolean> {
    const queryParams = {
      license: license
    };

    return this.http.get<string>(
      `${this.baseUrlAdmin}/license`,
      { params: queryParams, responseType: 'text' as 'json' }
    ).pipe(
      map(response => response.trim() === 'valid'),
      catchError(() => of(false))
    );
  }

  validateChaveAcesso(chaveAcesso: string | null): Observable<boolean> {
    if (chaveAcesso == '1a2b3c')
      return of(true);
    else
      return of(false);
  }

  validateEmailKey(chaveAcesso: string | null, email: string | null): Observable<HttpResponse<ILoginResponse>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      email: email,
      emailKey: chaveAcesso?.toUpperCase(),
      role: ['adm', 'corp']
    }

    return this.http.post<ILoginResponse>(
      `${this.baseUrlSeguranca}/emailKey`,
      body,
      { headers: headers, observe: 'response' }
    );
  }

  login(email: string): Observable<HttpResponse<ILoginResponse>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      email: email
    };

    return this.http.post<ILoginResponse>(
      `${this.baseUrlSeguranca}/login`, // Atualize a URL conforme necessário
      body,
      { headers: headers, observe: 'response' }
    );
  }
}
