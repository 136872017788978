<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>
<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>
<div class="body-div">
  <div class="content-div">
    <span class="title-text">Licenças</span>
    <form>
      <section class="top-table-section">
        <div class="search-container">
          <div class="filters-container">
            <div class="input-group inputs search-input-container">
              <div class="input-group-prepend">
                <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-search"></i></span>
              </div>

              <input type="text" class="form-control input-text" id="filtro" name="filtro" #input>
              <button class="filter-button" (click)="applyFilter()">
                Pesquisar
              </button>
            </div>

            <div class="combos-container">
              <div class="inputs-container">
                <label class="filters-label" for="filtroAtivo">Licença Ativa</label>
                <select class="form-select filters-select" id="filtroAtivo" name="filtroAtivo"
                  [(ngModel)]="filterActive" (change)="changeFilterActive()">
                  <option value="">Todos</option>
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                </select>
              </div>
              <div class="inputs-container">
                <label class="filters-label" for="filtroCartao">Cartão Gerado</label>
                <select class="form-select filters-select" id="filtroCartao" name="filtroCartao"
                  [(ngModel)]="filterCardGenerated" (change)="changeFilterCardGenerated()">
                  <option value="">Todos</option>
                  <option value="gerado">Gerado</option>
                  <option value="naoGerado">Não Gerado</option>
                </select>
              </div>
              <div class="inputs-container">
                <label class="filters-label" for="filtroVinculado">Licença Vinculada</label>
                <select class="form-select filters-select" id="filtroVinculado" name="filtroVinculado"
                  [(ngModel)]="filterLinked" (change)="changeFilterLinked()">
                  <option value="">Todos</option>
                  <option value="sim">Sim</option>
                  <option value="nao">Não</option>
                </select>
              </div>
            </div>
          </div>

          <div class="licence-generation-container" *ngIf="userProfile?.documentUser?.role == 'adm'">
            <div class="key-generation-container">
              <button class="key-generation-button" (click)="generateVerificationKey()">
                <i class="bi bi-key-fill"></i>
                Gerar Chave de Verificação
              </button>
              <span class="filters-label" *ngIf="verificationKey">{{ verificationKey }}</span>
            </div>
            <button class="licence-generation-button" (click)="openGerarLicencasModal()">
              <i class="bi bi-qr-code"></i>
              Gerar Licenças
            </button>
          </div>
        </div>

        <div class="licencas-div margin-top">
          <span class="licencas-text">Licenças disponíveis: {{ licencasDisponiveis }}/{{ totalLicencas }}</span>
          <span class="licencas-text">Licenças vinculadas: {{ linkedLicenses }}</span>
        </div>
      </section>

      <div class="table-container">
        <table class="table table-borderless" mat-table [dataSource]="paginatedData">
          <ng-container matColumnDef="createDate">
            <th mat-header-cell *matHeaderCellDef> Data de Criaçāo </th>
            <td mat-cell *matCellDef="let row">
              {{row.documentLicense.createDate
              ? (row.documentLicense.createDate | date: 'dd/MM/yyyy - HH:mm')
              : '-'
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="licenca">
            <th mat-header-cell *matHeaderCellDef> Licença </th>
            <td mat-cell *matCellDef="let row">
              <div class="icons-container">
                <button class="icon-button" (click)="openLinkLicenca(row.documentLicense.license)">
                  <i class="bi bi-qr-code icon-large"></i>
                </button>

                <!-- <button class="icon-button" (click)="copyLicenseToClipboard(row.documentLicense.license)">
                  <i class="bi bi-clipboard icon-large"></i>
                </button> -->

                <button *ngIf="row?.documentLicense?.emailOwner && !row?.documentUser?.email" class="icon-button"
                  (click)="resendLicennse(row.documentLicense.license)" matTooltip="Reenviar licença"
                  aria-label="Reenviar licença">
                  <i class="bi bi-envelope icon-large"></i>
                </button>

                <button class="icon-button" *ngIf="row.documentLicense.paymentIntentId"
                  [matTooltip]="row.documentLicense.paymentIntentId" aria-label="row.documentLicense.paymentIntentId">
                  <i class="bi bi-stripe icon-large"></i>
                </button>

                <span *ngIf="row.documentLicense?.tag" class="tag-like">{{row.documentLicense.tag}}</span>
              </div>
              <span
                [cdkCopyToClipboard]="copyLicenseToClipboard(row.documentLicense.license)">{{row.documentLicense.license}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef> Empresa </th>
            <td mat-cell *matCellDef="let row" [style.text-align]="!row.documentUser?.email ? 'center' : ''">
              {{row.documentLicense?.company ? row.documentLicense.company : '-'}} </td>
          </ng-container>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef> Primeiro Nome </th>
            <td mat-cell *matCellDef="let row" [style.text-align]="!row.documentUser?.email ? 'center' : ''">
              <button class="icon-button margin-right" *ngIf="row.documentUser?.email"><i
                  class="bi bi-file-person person-icon"
                  (click)="openDadosModal({documentUser: row.documentUser, documentLicense: row.documentLicense})"></i></button>
              {{row.documentUser?.personal?.name ? row.documentUser.personal.name : '-'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> E-mail Vinculado </th>
            <td mat-cell *matCellDef="let row" [style.text-align]="!row.documentUser?.email ? 'center' : ''">
              {{row.documentUser?.email ? row.documentUser.email : '-'}} </td>
          </ng-container>

          <ng-container matColumnDef="emailKey">
            <th mat-header-cell *matHeaderCellDef> Última Chave </th>
            <td mat-cell *matCellDef="let row" [style.text-align]="!row.documentUser?.personal?.name ? 'center' : ''">
              {{row.documentUser?.emailKey ? row.documentUser.emailKey : '-'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef> Ativar / desativar </th>
            <td mat-cell *matCellDef="let row" class="center-text">
              <div class="form-check form-switch" *ngIf="row.documentUser">
                <input type="checkbox" role="switch" class="form-check-input" name="active"
                  [checked]="row.documentUser.active" (change)="changeActive(row.documentLicense.idUser)">
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="isCardGeneratedToggle">
            <th mat-header-cell *matHeaderCellDef> Cartāo Gerado </th>
            <td mat-cell *matCellDef="let row" class="center-text">
              <div class="form-check form-switch">
                <input type="checkbox" role="switch" class="form-check-input" name="isCardGenerated"
                  [checked]="row.documentLicense.isCardGenerated"
                  (change)="toggleIsCardGenerated(row.documentLicense.license)">
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="dataVinculo">
            <th mat-header-cell *matHeaderCellDef> Data de Vínculo </th>
            <td mat-cell *matCellDef="let row"> {{row.documentLicense.linkDate ?
              (row.documentLicense.linkDate | date: 'dd/MM/yyyy') :
              row.documentUser?.email ? '-' : 'sem vínculo'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef> Administrador </th>
            <td mat-cell *matCellDef="let row">
              <div class="admin-column-value">
                <button *ngIf="row.documentLicense.emailOwner && userProfile?.documentUser?.role == 'adm'"
                  class="admin-configs-button"
                  (click)="openReplaceLicensesOwnerModal(row.documentLicense.emailOwner, row.documentLicense.license)">
                  <i class="bi bi-gear" style="font-size: 12px;"></i>
                </button>

                <button *ngIf="!row.documentLicense.emailOwner" class="admin-configs-button"
                  (click)="openReplaceLicensesOwnerModal(row.documentLicense.emailOwner, row.documentLicense.license)">
                  <i class="bi bi-plus" style="font-size: 12px;"></i>
                </button>

                {{row.documentLicense.emailOwner ? row.documentLicense.emailOwner : '-'}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="acao">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <div class="license-action-buttons-container">
                <button class="icon-button">
                  <i class="bi bi-x-lg icon-large" *ngIf="row.documentLicense.idUser"
                    (click)="unlinkLicense(row.documentLicense.license, row.documentUser.email)">
                  </i>
                  <i class="bi bi-link icon-large" *ngIf="!row.documentLicense.idUser"
                    (click)="openVinculaLicencaModal(row.documentLicense.license)">
                  </i>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5"></td>
          </tr>
        </table>
      </div>
      <mat-paginator [length]="dataSource.filteredData.length" [pageSize]="pageSize"
        [pageIndex]="pageIndex" (page)="pageChange($event)" [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
      <!-- <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageSize]="pageSize"
                [pageIndex]="pageIndex" (page)="pageChange()"></mat-paginator> -->
    </form>
  </div>
</div>
