import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Verifique se a URL da solicitação é para o endpoint que não requer autenticação
        const isUnauthenticatedEndpoint = request.url.includes('/api/bizConnect/security/login') ||
            request.url.includes('/api/bizConnect/security/emailKey') ||
            request.url.includes('/api/bizConnect/security/validateToken') ||
            // request.url.includes('/api/bizConnect/admin/license') ||
            request.url.includes('/api/bizConnect/admin/verificationKey') ||
            request.url.includes('/api/bizConnect/admin/firstAccess');

        if (isUnauthenticatedEndpoint) {
            // Se for um endpoint não autenticado, não adicione o cabeçalho "Authorization"
            return next.handle(request);
        } else {
            // Caso contrário, adicione o cabeçalho "Authorization"
            const token = localStorage.getItem('authToken');
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

            return next.handle(request).pipe(
              catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  this.authService.logout();
                }
                return throwError(error);
              })
            );
        }
    }
}
