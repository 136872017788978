import { AuthService } from './../auth/auth.service';
import { Component } from '@angular/core';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { AdmService, UserAdminPlan } from './adm.service';
import { HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-adm',
  templateUrl: './adm.component.html',
  styleUrls: ['./adm.component.css']
})
export class AdmComponent {
  active = 6;
  userProfile: IUserProfileWithCSS | null = null;
  userAdminPlan!: UserAdminPlan;
  response: string = "";
  aparenciaSelected = false;
  menuSelected = false;
  codigosAtivacaoSelected = false;
  indicadoresSelected = false;
  contasAtivasSelected = false;
  dashboardSelected = true;
  businessSelected = false;
  salesDashboardSelected = false;
  corpSluperCoinsSelected = false;
  corpTemplatesSelected = false;
  corpApplicationTemplatesSelected = false;
  mobileMenuOpened = false

  idUser: string = '';

  screenWidth: any;
  screenHeight: any;

  disableMenu: boolean = false;

  loading = true;

  constructor(
    private admService: AdmService,
    private authService: AuthService
  ) {
    this.loading = true;
    this.getUserById();
    this.getUserAdminPlan();
  }

  getPlanStampStyles() {
    const planStampStyles: {[k: string]: string} = {
      'Plano Básico': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
      'free plan 2': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
    }

    const defaultPlanStyle = "background-color: transparent; color: #9733EE; background: linear-gradient(to right, #DA22FF, #9733EE, #9B51E0); border-radius: 28px;"

    return this.userAdminPlan?.planName ? planStampStyles[this.userAdminPlan.planName] || defaultPlanStyle : defaultPlanStyle;
  }

  getUserById(): void {
    this.admService.userById(null).
      pipe(
        filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
        map((response: HttpResponse<IUserProfileWithCSS>) => response.body))
      .subscribe({
        next: (res) => this.setUserProfile(res),
        error: (res) => console.log(res)
      });
  }

  getUserAdminPlan(): void {
    this.admService
      .getAdmPlan()
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userAdminPlan = res;
          this.loading = false;
        },
        error: (res) => {
          console.log(res)
          this.loading = false;
        },
      });
  }

  setUserProfile(res: IUserProfileWithCSS | null) {
    this.userProfile = res;

    if (this.userProfile) {
      if (this.userProfile.documentUserProfile != null && this.userProfile.documentUser != null) {
        this.dashboardSelected = true;
        this.active = 6;
      }
    }
  }

  mudouMenu(selecionado: string) {
    if (selecionado === 'aparencia') {
      this.active = 2;
      this.aparenciaSelected = true;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.dashboardSelected = false;
      this.contasAtivasSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'menu') {
      this.active = 1;
      this.aparenciaSelected = false;
      this.menuSelected = true;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.dashboardSelected = false;
      this.contasAtivasSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'codigosAtivacao') {
      this.active = 3;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = true;
      this.indicadoresSelected = false;
      this.dashboardSelected = false;
      this.contasAtivasSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'indicadores') {
      this.active = 5;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.dashboardSelected = false;
      this.indicadoresSelected = true;
      this.contasAtivasSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'contasAtivas') {
      this.active = 4;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.dashboardSelected = false;
      this.contasAtivasSelected = true;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'dashboard') {
      this.active = 6;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.contasAtivasSelected = false;
      this.dashboardSelected = true;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'business') {
      this.active = 7;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.contasAtivasSelected = false;
      this.dashboardSelected = false;
      this.businessSelected = true;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'sales-dashboard') {
      this.active = 8;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.contasAtivasSelected = false;
      this.dashboardSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = true;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'corp-sluper-coins') {
      this.active = 9;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.contasAtivasSelected = false;
      this.dashboardSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = true;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = false;
    } else if (selecionado === 'business-templates') {
      this.active = 10;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.contasAtivasSelected = false;
      this.dashboardSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = true;
      this.corpApplicationTemplatesSelected = false;
    }else if (selecionado === 'application-templates') {
      this.active = 12;
      this.aparenciaSelected = false;
      this.menuSelected = false;
      this.codigosAtivacaoSelected = false;
      this.indicadoresSelected = false;
      this.contasAtivasSelected = false;
      this.dashboardSelected = false;
      this.businessSelected = false;
      this.salesDashboardSelected = false;
      this.corpSluperCoinsSelected = false;
      this.corpTemplatesSelected = false;
      this.corpApplicationTemplatesSelected = true;
    }
    this.toggleMobileMenu();
  }

  toggleMobileMenu() {
    this.mobileMenuOpened = !this.mobileMenuOpened;
  }

  logout(){
    this.authService.logout();
  }
}
