import { Component, Input, ViewChild } from '@angular/core';
import { NgbAlert, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, } from 'rxjs';

@Component({
  selector: 'profiles-accessed-ranking-modal',
  templateUrl: './profiles-accessed-ranking-modal.component.html',
  styleUrls: ['./profiles-accessed-ranking-modal.component.css']
})
export class ProfilesAccessedRankingModalComponent {
  @Input() profiles: {
    count: number;
    userProfile: {
      idUser: string;
      publish: boolean;
      parent: string;
      listURI: any[];
      listSocialNetwork: any[];
      listUriImages: any[];
      header: any;
      listButtons: any[];
      bio: string;
      name: string;
      uriImageBackground: string | null;
      uriImageProfile?: string | null;
    };
    userProfileCSS: {
      idUser: string;
      createDate: string;
      updateDate: string;
      css: any;
    };
  }[] = [];

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  getBackgroundImg(path: string | null | undefined): string {
    if(!path) {
      return "white";
    }

    return `url(${path})`;
  }

  getBackground(userProfile: {uriImageBackground: string | null | undefined;}, userCss: any): string | undefined {
    if(userProfile.uriImageBackground){
      return 'image';
    }

    // if (this.screenWidth > 550 && this.screenHeight > 550) {
    if (userCss) {
      if (userCss.corSecundaria) {
        return 'linear-gradient(' + userCss.direcaoDegrade + 'deg, ' + userCss.corPrimaria + ' 0%, ' + userCss.corSecundaria + ' 100%)'
      } else {
        return userCss.corPrimaria;
      }
    } else {
      return undefined;
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
