import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { BusinessService } from 'src/app/business/business.service';
import { LISTA_ESTILOS } from 'src/app/model/estilos';
import { LISTA_FONTES } from 'src/app/model/fontes';
import { Estilo } from 'src/app/model/styles.model';

export interface IUserCss {
  corPrimaria?: string;
  corSecundaria?: string;
  direcaoDegrade?: string;
  formato?: string;
  espessura?: string;
  sombra?: string;
  preenchimento?: string;
  contorno?: string;
  curvaBorda?: string;
  fonte?: string;
  negrito?: boolean;
  italico?: boolean;
  corFonte?: string;
  fontePerfil?: string;
  negritoPerfil?: boolean;
  italicoPerfil?: boolean;
  corFontePerfil?: string;
  cardBackgroundImageUrl?: string;
  callingCardImageUrl?: string;
}

@Component({
  selector: 'app-application-templates',
  templateUrl: './application-templates.component.html',
  styleUrls: ['./application-templates.component.css'],
})
export class ApplicationTemplatesComponent implements OnInit {
  fonts = LISTA_FONTES;
  defaultBackgroundStyles = LISTA_ESTILOS;

  @Output() templateSaved = new EventEmitter<boolean>();
  @Output() backToManageView = new EventEmitter<boolean>();
  @Output() templateCreationError = new EventEmitter<string>();

  templateName = '';
  defaultTemplate = false;
  templateCss: IUserCss = {};
  changeImageEvent: any = null;
  croppedBgImage: any = null;
  menuLogoImage?: File;
  callingCardBackgroundImage?: File;
  cardImage?: File;
  templateBgImgSelctionModalOpen = false;
  templateBgType: 'color' | 'image' = 'color';
  isColorBgSelected = true;
  isImageBgSelected = false;
  imageTypeToBeUploaded = '';
  openedSection = 'templateInfo';

  toggleSection(section: string) {
    this.openedSection = section;
  }

  @ViewChild('templateBgImgSelctionModal') templateBgImgSelctionModal: any;

  ngOnInit(): void {
    console.log(this.imageTypeToBeUploaded)
  }

  constructor(
    private businessService: BusinessService,
    private modalService: NgbModal,
  ) {
    this.templateCss = {
      corPrimaria: "#9d96f8",
      corSecundaria: "#fb9965",
      direcaoDegrade: "285",
      sombra: "",
      espessura: "0px",
      preenchimento: "#ffffff",
      contorno: "#ffffff",
      curvaBorda: "8",
      fonte: "Wix Madefor Text",
      corFonte: "#000000",
      negrito: false,
      italico: false,
      fontePerfil: "Wix Madefor Text",
      corFontePerfil: "#ffffff",
      negritoPerfil: false,
      italicoPerfil: false
    };

    return;
  }

  changeTextStyle(style: 'bold' | 'italic') {
    if (style === 'bold') {
      this.templateCss.italicoPerfil = false;
      this.templateCss.negritoPerfil = !this.templateCss.negritoPerfil;

      return;
    }

    this.templateCss.italicoPerfil = !this.templateCss.italicoPerfil;
    this.templateCss.negritoPerfil = false;
  }

  changeTextLinkStyle(style: 'bold' | 'italic') {
    if (style === 'bold') {
      this.templateCss.italico = false;
      this.templateCss.negrito = !this.templateCss.negrito;

      return;
    }

    this.templateCss.italico = !this.templateCss.italico;
    this.templateCss.negrito = false;
  }

  changeLinkButtonShadow(s: string) {
    this.templateCss.sombra = s;
  }

  changeLinkButtonRadius(r: string) {
    this.templateCss.curvaBorda = r;
  }

  applyDefaultBackgroundStyle(i: number) {
    this.changeBackgroundType('color');

    const defaultStyle = this.defaultBackgroundStyles[i];

    this.templateCss.corPrimaria = defaultStyle.externo.corPrincipal;
    this.templateCss.corSecundaria = defaultStyle.externo.corSecundaria;
    this.templateCss.direcaoDegrade = defaultStyle.externo.direcaoDegrade;
    this.templateCss.preenchimento = defaultStyle.interno.background;
    this.templateCss.contorno = defaultStyle.interno.borderColor;
    this.templateCss.curvaBorda = defaultStyle.interno.borderRadius;
    this.templateCss.espessura = defaultStyle.interno.borderWidth;
    this.templateCss.fonte = 'Wix Madefor Text';
    this.templateCss.fontePerfil = 'Wix Madefor Text';
    this.templateCss.cardBackgroundImageUrl = undefined;
  }

  getBackground(style?: Estilo): string {
    if(style) {
      if (style?.externo.corSecundaria){
        return (
          'linear-gradient(' +
          style.externo.direcaoDegrade +
          'deg, ' +
          style.externo.corPrincipal +
          ' 0%, ' +
          style.externo.corSecundaria +
          ' 100%)'
        );
      }

      return style.externo.corPrincipal;
    }

    if (this.templateCss.corSecundaria) {
      return (
        'linear-gradient(' +
        this.templateCss.direcaoDegrade +
        'deg, ' +
        this.templateCss.corPrimaria +
        ' 0%, ' +
        this.templateCss.corSecundaria +
        ' 100%)'
      );
    }

    return this.templateCss.corPrimaria || '#9d96f8';
  }

  changeLinksWidth(w: string) {
    this.templateCss.espessura = w;
  }

  changeBackgroundType(type: string) {
    if(this.templateBgType === type) return;

    if (this.templateBgType === 'color') {
      this.templateBgType = 'image';
      this.isColorBgSelected = false;
      this.isImageBgSelected = true;

      return;
    }

    this.isColorBgSelected = true;
    this.isImageBgSelected = false;
    this.templateBgType = 'color';
    this.templateCss.cardBackgroundImageUrl = undefined;
    this.croppedBgImage = null;
    this.changeImageEvent = null;
  }

  backToManageTemplates() {
    this.backToManageView.emit(true);
  }

  async saveTemplate() {
    if (!this.templateName) {
      this.templateCreationError.emit('Nome do template é obrigatório.');

      return;
    }

    if (this.menuLogoImage) {
      try {
        const response = await firstValueFrom(this.businessService.uploadFile(this.menuLogoImage))

        this.templateCss.cardBackgroundImageUrl = response;
      } catch (e: any) {
        this.templateCreationError.emit(e.error.message || 'Erro ao subir imagem de fundo do template.');

        return;
      }
    }
  }

  openUploadTemplateImages(type: 'background' | 'card') {
    this.imageTypeToBeUploaded = type;
    this.templateBgImgSelctionModalOpen = true;

    const modalRef = this.modalService.open(this.templateBgImgSelctionModal, {
      size: 'lg',
      centered: true,
    });
    modalRef.result
      .then(() => {
        this.templateBgImgSelctionModalOpen = false;
      })
      .catch(() => {
        this.templateBgImgSelctionModalOpen = false;
      });
  }

  removeCallingCardImage() {
    this.templateCss.callingCardImageUrl = undefined;
    this.callingCardBackgroundImage = undefined;
  }

  templateBgImageChanged(event: any): void {
    const file: File = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    if (file.size <= maxSizeInBytes) {
      this.changeImageEvent = event;
    } else {
      // this.alertType = 'danger';
      // this._alert.next(
        // 'Tamanho da arquivo é maior do que o permitido. Favor selecionar uma imagem de até 2MB.',
      // );
    }
  }

  templateImageCropped(event: any): void {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;

        var image = new Image();
        image.src = e.target.result as string;

        this.croppedBgImage = reader.result as string;
        image.onload = () => {
          const targetAspectRatio = 125 / 45;

          const imageAspectRatio = image.width / image.height;
          const tolerance = 2;

          if (Math.abs(targetAspectRatio - imageAspectRatio) > tolerance) {
            alert("A imagem deve ter proporção de 617x149.");
          }
        };

      };

      reader.readAsDataURL(blob);
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  confirmTemplateImageCropped(croppedImage: string): void {
    if (croppedImage) {
      const blob = this.dataURItoBlob(croppedImage);
        this.templateCss.cardBackgroundImageUrl = this.croppedBgImage;

        this.menuLogoImage = new File(
          [blob],
          'sluper_logo.jpeg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );

      this.changeImageEvent = null;

      this.modalService.dismissAll();
    }
  }
}
