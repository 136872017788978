import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LicencasService } from '../../licencas.service';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IResponse } from 'src/app/model/response.model';
import { ILicense } from 'src/app/model/license.model';

@Component({
  selector: 'app-assign-license-owner',
  templateUrl: './assign-license-owner.component.html',
  styleUrls: ['./assign-license-owner.component.css']
})
export class AssignLicenseOwnerComponent implements OnInit {
  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  newOwnerEmail = '';
  @Input() licenseId = '';
  @Input() onSuccess: () => void = () => {};

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService
  ) { }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  onError(res: any) {
    if (res.error.status == "ERROR") {
      this.alertType = 'danger';
      this._alert.next(res.error.message);
    }
  }

  handleAssignLicenseOwner() {
    if (!this.newOwnerEmail) {
      this.alertType = 'danger';
      this._alert.next('O email do novo proprietário não pode ser vazio.');

      return;
    }

    if (!this.newOwnerEmail.includes('@') || !this.newOwnerEmail.includes('.')) {
      this.alertType = 'danger';
      this._alert.next('O email do novo proprietário não é válido.');

      return;
    }

    const emailRegex = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');

    if (!emailRegex.test(this.newOwnerEmail)) {
      this.alertType = 'danger';
      this._alert.next('O email do novo owner não é válido.');

      return;
    }

    this.licencasService.assignLicenseOwner(this.licenseId, this.newOwnerEmail).pipe(
      filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe(res => {
      if (res?.status === 'SUCCESS') {
        this.alertType = 'success';
        this._alert.next(res.message);
        this.activeModal.close();
        this.onSuccess();

        return;
      }

      this.alertType = 'danger';
      this._alert.next(res?.message || 'Erro ao tentar substituir o proprietário das licenças.');
    });
  }
}
