<app-loading-spinner [bgTransparent]="false" *ngIf="loading"></app-loading-spinner>

<div class="background" *ngIf="!loading">
  <div class="nav-container">
    <img src="/assets/img/Marca Sluper_Positiva Horizontal.svg" alt="Marca Sluper" class="marca">

    <div class="mobile-menu-button-container">
      <a href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal" _target="blank"
        class="profile-plan-stamp mobile-stamp" [style]="getPlanStampStyles()">
        <span class="profile-plan-stamp-text">
          <i class="bi bi-collection"></i>
          {{ userAdminPlan && userAdminPlan.planName ? userAdminPlan.planName.toUpperCase() : '' }}
        </span>
      </a>

      <button class="mobile-menu-button" (click)="toggleMobileMenu()">
        <i class="bi bi-list"></i>
      </button>
    </div>

    <div [ngClass]="mobileMenuOpened === true ? 'menu-buttons-opened' : 'menu-buttons'" ngbNav #nav="ngbNav"
      [activeId]="active">
      <ng-container ngbNavItem [ngbNavItem]="3">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('codigosAtivacao')" [disabled]="disableMenu" *ngIf="(userProfile != null) &&
          ((userProfile.documentUser != null && userProfile.documentUser.role == 'adm') ||
          (userProfile.documentAdmin != null && userProfile.documentAdmin.role == 'adm'))
          ">
          <i class="bi bi-qr-code menu-icon"
            [style.color]="codigosAtivacaoSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i>
          <span class="menu-text"
            [style.color]="codigosAtivacaoSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.codigosAtivacao</span>
        </button>
        <ng-template ngbNavContent>
          <app-licencas [userProfile]="userProfile"></app-licencas>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="6">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('dashboard')" [disabled]="disableMenu">
          <i class="bi bi-bar-chart-fill"
            [style.color]="dashboardSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i>
          <span class="menu-text"
            [style.color]="dashboardSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.dashboard</span>
        </button>
        <ng-template ngbNavContent>
          <app-dashboard [adminPlan]="userAdminPlan" [userProfile]="userProfile"></app-dashboard>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="7">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('business')" [disabled]="disableMenu">
          <i class="bi bi-briefcase menu-icon"
            [style.color]="businessSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i>
          <span class="menu-text"
            [style.color]="businessSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.business</span>
        </button>
        <ng-template ngbNavContent>
          <app-business [userProfile]="userProfile"></app-business>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="8">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('sales-dashboard')" [disabled]="disableMenu">
          <i class="bi bi-speedometer menu-icon" [style.color]=" salesDashboardSelected ? 'var(--colors-complementary-complementary-green, #00CECE)'
            : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i><span class=" menu-text"
            [style.color]="salesDashboardSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.salesDashboard</span>
        </button>
        <ng-template ngbNavContent>
          <app-sales-dashboard [userProfile]="userProfile"></app-sales-dashboard>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="9" *ngIf="userProfile?.documentAdmin?.sluperCoinEnabled">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('corp-sluper-coins')" [disabled]="disableMenu">
          <i class="bi bi-coin menu-icon" [style.color]="corpSluperCoinsSelected ? 'var(--colors-complementary-complementary-green, #00CECE)'
            : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i><span class=" menu-text"
            [style.color]="corpSluperCoinsSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.corpSluperCoins</span>
        </button>
        <ng-template ngbNavContent>
          <app-corp-sluper-coins [userProfile]="userProfile"></app-corp-sluper-coins>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem [ngbNavItem]="10" *ngIf="userProfile?.documentAdmin">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('business-templates')" [disabled]="disableMenu">
          <i class="bi bi-card-heading menu-icon" [style.color]="corpTemplatesSelected ? 'var(--colors-complementary-complementary-green, #00CECE)'
            : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i>

          <span class=" menu-text"
            [style.color]="corpTemplatesSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.businessTemplates</span>
        </button>

        <ng-template ngbNavContent>
          <app-business-templates [adminPlan]="userAdminPlan" [userProfile]="userProfile"></app-business-templates>
        </ng-template>
      </ng-container>

      <!-- <ng-container ngbNavItem [ngbNavItem]="12">
        <button class="icons-button" ngbNavLink (click)="mudouMenu('applications-templates')" [disabled]="disableMenu">
          <i class="bi bi-card-heading menu-icon" [style.color]="corpApplicationTemplatesSelected ? 'var(--colors-complementary-complementary-green, #00CECE)'
            : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"></i>

          <span class=" menu-text"
            [style.color]="corpApplicationTemplatesSelected ? 'var(--colors-complementary-complementary-green, #00CECE)' : 'var(--colors-neutrals-neutral-xx-light, #FFF)'"
            translate>menu.applicationTemplates</span>
        </button>

        <ng-template ngbNavContent>
          <app-application-templates ></app-application-templates>
        </ng-template>
      </ng-container> -->

      <ng-container ngbNavItem [ngbNavItem]="11">
        <button class="icons-button" ngbNavLink (click)="logout()">
          <img src="/assets/img/box-arrow-right.svg" alt="Sair" class="menu-icon" />
          <span class="menu-text"
          [style.color]="'var(--colors-neutrals-neutral-xx-light, #FFF)'"
          translate>menu.sair</span>
        </button>
      </ng-container>

      <div class="profile-stamp-container">
        <a *ngIf="!mobileMenuOpened"
          href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal" _target="blank"
          class="profile-plan-stamp" [style]="getPlanStampStyles()">
          <span class="profile-plan-stamp-text">
            <i class="bi bi-collection"></i>
            {{ userAdminPlan && userAdminPlan.planName ? userAdminPlan.planName.toUpperCase() : '' }}
          </span>
        </a>
      </div>
    </div>

    <div *ngIf="mobileMenuOpened === true" class="menu-overlay" (click)="toggleMobileMenu()"></div>
  </div>

  <div [ngbNavOutlet]="nav"></div>
</div>
