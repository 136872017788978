import { Component, EventEmitter, HostListener, Input, DoCheck, Output, ViewChild, OnChanges, SimpleChanges, } from '@angular/core';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { DashboardService, IDashboardData } from './dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfilesAccessedRankingModalComponent } from './profiles-accessed-ranking-modal/profiles-accessed-ranking-modal.component';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke
} from "ng-apexcharts";
import { addDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs';
import { AdmService, UserAdminPlan } from '../adm/adm.service';
import { IDashboardAdmin } from '../model/dashboard.model';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

interface IFormattedDashboardData {
  acessedLinksCount: number;
  profileAccessCount: number;
  socialNetworkAcessedCount: number;
  generatedCardsCount: number;
  enabledLicensesCount: number;
  stripeLicensesCount: number;
  unavailableLicenses: number;
  availableLicenses: number;
  generatedLicenses: number;
  profileAccessByWeeks: number[];
  linksClickedByWeeks: number[];
  socialNetworkByWeeks: number[];
  linkedLicenses: number;
  profileAccessCountList?: {
    count?: number;
    idUser?: string;
    userProfile?: {
      bio?: string;
      name?: string;
      uriImageBackground?: string;
      uriImageProfile?: string;
    };
    userProfileCSS?: {
      createDate?: string;
      css?: string;
    };
  }[];
  topAccessedSocialNetworks: {
    url: string;
    count: number;
  }[];
  topAccessedLinks: {
    url: string;
    count: number;
  }[];
  updateDate?: Date;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnChanges {
  @ViewChild("chart") chart?: ChartComponent;
  public chartOptions: ChartOptions;

  cabecalhoVisible: boolean = true;

  @Input() adminPlan!: UserAdminPlan;
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Output() linkRequired = new EventEmitter<boolean>();

  dashboardData: IFormattedDashboardData | null = null;

  screenWidth: any;
  screenHeight: any;

  filterActive = '';
  filterOptions: { idUser: string; name: string; email: string }[] = [];
  filteredOptions: { idUser: string; name: string; email: string }[];

  lastForWeeksPeriods: string[] = [];

  dashboardEventAdmin: IDashboardAdmin | null = null;

  constructor(
    private admService: AdmService,
    private dashboardService: DashboardService,
    private modalService: NgbModal
  ) {
    this.filteredOptions = [];
    this.findAllUsersDashboard();
    this.findEventsAdminDashboard();

    this.getScreenSize();

    let lastForWeeksPeriods: string[] = [];
    const currentDate = new Date();

    for(let i = 0; i < 4; i++) {
      const currentWeekStartDate = addDays(currentDate, -7 * (i + 1));
      const currentWeekEndDate = addDays(currentWeekStartDate, i === 0 ? 7 : 6);

      this.lastForWeeksPeriods.push(`${format(currentWeekStartDate, "dd'/'MM'/'yyyy", { locale: ptBR })} a ${format(currentWeekEndDate, "dd'/'MM'/'yyyy", { locale: ptBR })}`);
    }

    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories: lastForWeeksPeriods
      },
      tooltip: {
      }
    };
  }

  findEventsAdminDashboard() {
    this.dashboardService.getEventDashboadAdmin().subscribe(({ body }) => {
      if (body) {
        this.dashboardEventAdmin = body;
      }
    });
  }

  findAllUsersDashboard() {
    this.dashboardService.findAllUsersDashboard().pipe(
      filter((mayBeOk: HttpResponse<{ idUser: string; name: string; email: string }[]>) => mayBeOk.ok),
      map((response: HttpResponse<{ idUser: string; name: string; email: string }[]>) => response.body)
    ).subscribe({
      next: (res) => {
        this.filterOptions = res || [];
      },
      error: (res: any) => console.log(res)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const adminPlanChanged = changes['adminPlan'];

    if (adminPlanChanged && this.adminPlan?.adminPlanLimits?.dashViews) {
      let lastForWeeksPeriods: string[] = [];
      const currentDate = new Date();

      for(let i = 0; i < 4; i++) {
        const currentWeekStartDate = addDays(currentDate, -7 * (i + 1));
        const currentWeekEndDate = addDays(currentWeekStartDate, i === 0 ? 7 : 6);

        this.lastForWeeksPeriods.push(`${format(currentWeekStartDate, "dd'/'MM'/'yyyy", { locale: ptBR })} a ${format(currentWeekEndDate, "dd'/'MM'/'yyyy", { locale: ptBR })}`);
      }

      this.chartOptions = {
        series: [],
        chart: {
          height: 350,
          type: "area"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          categories: lastForWeeksPeriods
        },
        tooltip: {
        }
      };

      this.dashboardService.getDashboardData().subscribe(({ body }) => {
        if(body) {
          this.dashboardData = {
            acessedLinksCount: body.acessedLinksCount,
            profileAccessCount: body.profileAccessCount,
            socialNetworkAcessedCount: body.socialNetworkAcessedCount,
            generatedCardsCount: body.generatedCardsCount,
            enabledLicensesCount: body.enabledLicensesCount,
            stripeLicensesCount: body.stripeLicensesCount,
            topAccessedLinks: body.topAccessedLinks,
            topAccessedSocialNetworks: body.topAccessedSocialNetworks,
            linkedLicenses: body.linkedLicenses,
            availableLicenses: body.availableLicenses,
            unavailableLicenses: body.unavailableLicenses,
            generatedLicenses: body.generatedLicenses,
            profileAccessByWeeks: body.profileAccessByWeeks,
            linksClickedByWeeks: body.linksClickedByWeeks,
            socialNetworkByWeeks: body.socialNetworkByWeeks,
            profileAccessCountList: body.profileAccessCountList.map((profile) => ({
              ...profile,
              userProfileCSS: {
                ...profile.userProfileCSS,
                css: profile?.userProfileCSS?.css ? JSON.parse(profile.userProfileCSS.css) : ''
              }
            })),
            updateDate: body.updateDate,
          };

          if(this.dashboardData) {
            this.chartOptions = {
              series: [
                {
                  name: "Perfil",
                  data: this.dashboardData.profileAccessByWeeks.reverse()
                },
                {
                  name: "Redes sociais",
                  data: this.dashboardData.socialNetworkByWeeks.reverse()
                },
                {
                  name: "Links",
                  data: this.dashboardData.linksClickedByWeeks.reverse()
                },
              ],
              chart: {
                height: 350,
                type: "area"
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: "smooth"
              },
              xaxis: {
                categories: this.lastForWeeksPeriods.reverse()
              },
              tooltip: {
              }
            };
          }
        }
      })
    }
  }

  getDashboardInfo(idUser?:string): void {
    this.dashboardService.getDashboardData(idUser).subscribe(({ body }) => {
      if(body) {
        this.dashboardData = {
          acessedLinksCount: body.acessedLinksCount,
          profileAccessCount: body.profileAccessCount,
          socialNetworkAcessedCount: body.socialNetworkAcessedCount,
          generatedCardsCount: body.generatedCardsCount,
          enabledLicensesCount: body.enabledLicensesCount,
          stripeLicensesCount: body.stripeLicensesCount,
          topAccessedLinks: body.topAccessedLinks,
          topAccessedSocialNetworks: body.topAccessedSocialNetworks,
          linkedLicenses: body.linkedLicenses,
          availableLicenses: body.availableLicenses,
          unavailableLicenses: body.unavailableLicenses,
          generatedLicenses: body.generatedLicenses,
          profileAccessByWeeks: body.profileAccessByWeeks,
          linksClickedByWeeks: body.linksClickedByWeeks,
          socialNetworkByWeeks: body.socialNetworkByWeeks,
          profileAccessCountList: body.profileAccessCountList?.map((profile) => ({
            ...profile,
            userProfileCSS: {
              ...profile.userProfileCSS,
              css: profile?.userProfileCSS?.css ? JSON.parse(profile.userProfileCSS.css) : ''
            }
          })),
          updateDate: body.updateDate,
        };

        this.chartOptions = {
          series: [
            {
              name: "Perfil",
              data: this.dashboardData?.profileAccessByWeeks?.reverse()
            },
            {
              name: "Redes sociais",
              data: this.dashboardData?.socialNetworkByWeeks?.reverse()
            },
            {
              name: "Links",
              data: this.dashboardData?.linksClickedByWeeks?.reverse()
            },
          ],
          chart: {
            height: 350,
            type: "area"
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "smooth"
          },
          xaxis: {
            categories: this.lastForWeeksPeriods.reverse()
          },
          tooltip: {
          }
        };
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  openProfileAccessedRankingModal() {
    if (!this.dashboardData?.profileAccessCountList || !this.adminPlan.adminPlanLimits.dashTopProfileAccess) {
      return;
    }

    const modalRef = this.modalService.open(ProfilesAccessedRankingModalComponent, {centered: true, size: 'lg'});

    const hasNullFields = this.dashboardData?.profileAccessCountList?.some(profile =>
      profile.userProfile === null || profile.userProfileCSS === null
    );

    if (!hasNullFields) {
      modalRef.componentInstance.profiles = this.dashboardData?.profileAccessCountList;

      return;
    }

    this.fetchUserProfileData().then(() => {
      modalRef.componentInstance.profiles = this.dashboardData?.profileAccessCountList;
    });
  }

  async fetchUserProfileData(): Promise<void> {
    if (!this.dashboardData?.profileAccessCountList) {
      return;
    }

    await Promise.all(this.dashboardData.profileAccessCountList.map(async (profile) => {
      if (!profile.idUser || (profile.userProfileCSS !== null && profile.userProfile !== null)) {
        return;
      }

      this.admService.userById(profile.idUser).pipe(
        filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
        map((response: HttpResponse<IUserProfileWithCSS>) => response.body)
      ).subscribe({
        next: (res) => {
          profile.userProfile = res?.documentUserProfile;
          profile.userProfileCSS = {...res?.documentUserCSS, css: res?.documentUserCSS?.css ? JSON.parse(res.documentUserCSS.css) : ''};
        },
        error: (res) => console.log(res)
      });

      profile.userProfileCSS = {
        ...profile.userProfileCSS,
        css: profile?.userProfileCSS?.css ? JSON.parse(profile.userProfileCSS.css) : ''
      };
    }));
  }

  filterValues(event: any) {
    const value = event?.target?.value || '';

    if (value) {
      this.filteredOptions = this.filterOptions.filter(option =>
        (option.name && option.name.toLowerCase().includes(value.toLowerCase())) ||
        (option.email && option.email.toLowerCase().includes(value.toLowerCase()))
      );

      return;
    }

    this.filteredOptions = [];
    this.clearDashboard();
    this.getDashboardInfo();
  }

  selectOption(option: { idUser: string; name: string; email: string }) {
    this.filterActive = option.name ? `${option.name} - ${option.email}` : option.email;

    this.filteredOptions = [];

    this.clearDashboard();
    this.getDashboardInfo(option.idUser);
  }

  clearDashboard(){
    this.dashboardData = null;
  }
}
