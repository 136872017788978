import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { BusinessService, IUserTemplatesOutput, ICorpUserOutput } from '../business/business.service';
import { IUserCss } from './create-template/create-template.component';
import { Estilo } from '../model/styles.model';
import { Subject, debounceTime } from 'rxjs';
import { UserAdminPlan } from '../adm/adm.service';

interface IFormattedTemplate extends IUserTemplatesOutput {
  formattedCss: IUserCss;
  suspended: boolean;
}

@Component({
  selector: 'app-business-templates',
  templateUrl: './business-templates.component.html',
  styleUrls: ['./business-templates.component.css'],
})
export class BusinessTemplatesComponent implements OnInit {
  cabecalhoVisible = true;
  visualization: 'manage' | 'creation' = 'manage';

  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() adminPlan!: UserAdminPlan;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  screenWidth: any;
  screenHeight: any;

  templates: IFormattedTemplate[] = [];
  users: ICorpUserOutput[] = [];
  selectedUserIds: string[] = [];
  selectedTemplate: string = ''

  allowAccessOnlyByNFC = false;
  publicSearch = false;

  constructor(
    private businessService: BusinessService,
    private modalService: NgbModal,
  ) {
    this.getScreenSize();

    this.fetchCorpUsers();
    this.fetchCorpTemplates();

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  fetchCorpTemplates() {
    this.businessService.getUserTemplates().subscribe(({ body }) => {
      if (body) {
        this.templates = body.map((template, index) => {
          return {
            ...template,
            suspended: this.adminPlan.adminPlanLimits.templateQuantity !== -1 && (index + 1) > this.adminPlan.adminPlanLimits.templateQuantity,
            formattedCss: JSON.parse(template.css) as IUserCss
          }
        });
      }
    });
  }

  fetchCorpUsers() {
    this.businessService.getCorpUsers().subscribe(({ body }) => {
      if (body) {
        this.users = body;
      }
    });
  }

  ngOnInit(): void {
    return;
  }

  templateCreationError(message: string) {
    this.alertType = 'danger';
    this._alert.next(message);
  }

  templateSaved() {
    this.fetchCorpTemplates();
    this.visualization = 'manage';

    this.alertType = 'success';
    this._alert.next('Template salvo com sucesso!');
  }

  backToManageView() {
    this.fetchCorpTemplates();
    this.visualization = 'manage';
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(_event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  changeVisualization() {
    if (this.visualization === 'manage') {
      this.visualization = 'creation';

      return;
    }

    this.fetchCorpTemplates();

    this.visualization = 'manage';
  }

  getBackground(style: IUserCss): string {
    if (style?.corSecundaria) {
      return (
        'linear-gradient(' +
        style.direcaoDegrade +
        'deg, ' +
        style.corPrimaria +
        ' 0%, ' +
        style.corSecundaria +
        ' 100%)'
      );
    }

    return style.corPrimaria || '#f3f';
  }

  handleSelectTemplate(templateId: string) {
    const template = this.templates.find(template => template.id === templateId);

    if (!template || template.suspended) {
      return;
    }

    if (this.selectedTemplate === templateId) {
      this.selectedTemplate = '';

      return;
    }

    this.selectedTemplate = templateId;
  }

  handleSelectUsers(userId: string) {
    if (this.selectedUserIds.includes(userId)) {
      this.selectedUserIds = this.selectedUserIds.filter(id => id !== userId);

      return;
    }

    this.selectedUserIds.push(userId);
  }

  applyCorpTemplate(mode: 'SYNC' | 'COPY') {
    if (!this.selectedTemplate) {
      this.alertType = 'danger';
      this._alert.next('Selecione um template');

      return;
    }

    if (this.selectedUserIds.length === 0) {
      this.alertType = 'danger';
      this._alert.next('Selecione ao menos um usuário');

      return;
    }

    this.businessService.applyTemplate(this.selectedTemplate, mode, this.selectedUserIds, this.allowAccessOnlyByNFC, this.publicSearch).subscribe({
      next: () => {
        this.alertType = 'success';
        this._alert.next('Template aplicado com sucesso!');

        this.selectedUserIds = [];
        this.selectedTemplate = '';

        this.fetchCorpUsers();
      },
      error: () => {
        this.alertType = 'danger';
        this._alert.next('Erro ao aplicar template');
      }
    });
  }

  deleteTemplate(event: Event, templateId: string) {
    event.stopPropagation();

    this.businessService.deleteTemplate(templateId).subscribe({
      next: () => {
        this.alertType = 'success';
        this._alert.next('Template deletado com sucesso!');

        this.fetchCorpTemplates();
        this.fetchCorpUsers();
      },
      error: () => {
        this.alertType = 'danger';
        this._alert.next('Erro ao deletar template');
      }
    });
  }

  setDefaultTemplate(event: Event, templateId: string) {
    event.stopPropagation();

    this.businessService.setDefaultTemplate(templateId).subscribe({
      next: () => {
        this.alertType = 'success';
        this._alert.next('Template alterado com sucesso.!');

        this.fetchCorpTemplates();
        this.fetchCorpUsers();
      },
      error: () => {
        this.alertType = 'danger';
        this._alert.next('Erro ao alterar template');
      }
    });
  }

  unlinkTemplate(userId: string) {
    this.selectedUserIds = this.selectedUserIds.filter(id => id !== userId);

    this.businessService.unlinkTemplate(userId).subscribe({
      next: () => {
        this.alertType = 'success';
        this._alert.next('Template desvinculado com sucesso!');

        this.fetchCorpUsers();
      },
      error: () => {
        this.alertType = 'danger';
        this._alert.next('Erro ao desvincular template');
      }
    });
  }
}
