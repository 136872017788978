import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from './login.service';
import { ChaveAcessoModalComponent } from './chave-acesso-modal/chave-acesso-modal.component';
import { FormControl } from '@angular/forms';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  screenWidth: any;
  screenHeight: any;
  userEmail: any;
  userEmailInput: FormControl = new FormControl();
  emailError: string = '';

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private authService: AuthService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.usuariologado();
  }

  usuariologado() {
    if (this.authService.isAuthenticated()){
      this.router.navigateByUrl('admin-profile');
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  openChaveAcessoModal(email: any) {
    this.emailError = '';
    if (this.userEmailInput.valid && this.userEmail !== '' && !this.userEmailInput.errors?.['email']) {
      this.loginService.login(email).subscribe(
        response => {
          if (response.status === 200 && response.body?.status === 'SUCCESS') {

            let modalRef;
            if (this.screenWidth > 550) {
              modalRef = this.modalService.open(ChaveAcessoModalComponent, { centered: true });
            } else {
              modalRef = this.modalService.open(ChaveAcessoModalComponent, { fullscreen: true, centered: true });
            }

            modalRef.componentInstance.screenWidth = this.screenWidth;
            modalRef.componentInstance.userEmail = this.userEmail;
            modalRef.result
              .then(result => {
                if (result) {
                  this.authService.login(result);
                  this.router.navigateByUrl('admin-profile');
                } else {
                  this.emailError = 'Ocorreu um erro inesperado durante o acesso. Tente novamente mais tarde.';
                }
              })
              .catch(error => {
                if (error && error !== 1) {
                  this.emailError = error;
                }
              });
          } else {
            this.emailError = response.body?.message || 'Chave de acesso inválida';
          }
        },
        error => {
          console.error('Erro ao verificar a chave de acesso:', error);
          this.emailError = error.error?.message || 'Erro ao verificar a chave de acesso';
        }
      );
    }
  }
}
