import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbAlert, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { IPromosByUser, LicencasService } from 'src/app/licencas/licencas.service';
import { IResponse } from '../../model/response.model';

@Component({
  selector: 'app-create-promo-modal',
  templateUrl: './create-promo-modal.component.html',
  styleUrls: ['./create-promo-modal.component.css']
})
export class CreatePromoModalComponent {
  @Input() userId!: string;
  @Input() onSuccess!: () => void;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  participants: Array<{ id: string; name: string; email: string }> = [];

  promo: {
    promoName?: string;
    promoDescription?: string;
    frequency?: string;
    quantity?: number;
    startDate?: {day: number; month: number; year: number};
    endDate?: {day: number; month: number; year: number};
  } = {
      frequency: 'diario',
      quantity: 1,
  };
  promoFrequenciesOptions = [
    { value: 'diario', label: 'Diariamente' },
    // { value: 'mensal', label: 'Mensal' },
  ]
  formattedStartDate: string = '';
  formattedEndDate: string = '';
  userOptions: { idUser: string; name: string; email: string }[] = [];
  selectedUser: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private dashboardService: DashboardService,
    private licencasService: LicencasService
  ) {
    this.findAllUsers();
  }

  findAllUsers() {
    this.dashboardService.findAllUsersDashboard() .pipe(
      filter((mayBeOk: HttpResponse<{ idUser: string; name: string; email: string }[]>) => mayBeOk.ok),
      map((response: HttpResponse<{ idUser: string; name: string; email: string }[]>) => response.body)
    ).subscribe({
      next: (res) => {
        this.userOptions = res || [];
        console.log(this.userOptions);
      },
      error: (res: any) => console.log(res)
    });
  }

  selectUser(event: any) {
    const userId = event?.target?.value || '';

    if (!userId || userId.length < 5) {
      return;
    }

    const user = this.userOptions.find(option => option.idUser === userId);

    if (user) {
      this.participants.push({
        id: user.idUser,
        name: user.name,
        email: user.email
      });
      this.userOptions = this.userOptions.filter(option => option.idUser !== userId);
    }

    this.selectedUser = '';
  }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  removeUser(userId: string) {
    const participant = this.participants.find(participant => participant.id === userId)

    if (!participant) {
      return;
    }

    this.participants = this.participants.filter(participant => participant.id !== userId);
    this.userOptions.push({
      idUser: participant.id,
      name: participant.name,
      email: participant.email
    });
    this.selectedUser = '';
  }

  savePromo() {
    if (!this.promo.promoName || !this.promo.promoDescription || !this.promo.frequency || !this.promo.quantity || !this.promo.startDate || !this.promo.endDate) {
      this.alertMessage = 'Preencha todos os campos';
      this.alertType = 'danger';
      return;
    }

    if (this.participants.length === 0) {
      this.alertMessage = 'Selecione pelo menos um participante';
      this.alertType = 'danger';
      return;
    }

    const promo = {
      promoName: this.promo.promoName,
      promoDescription: this.promo.promoDescription,
      frequency: this.promo.frequency,
      quantity: this.promo.quantity,
      startDate: new Date(this.promo.startDate.year, this.promo.startDate.month - 1, this.promo.startDate.day),
      endDate: new Date(this.promo.endDate.year, this.promo.endDate.month - 1, this.promo.endDate.day),
      participants: this.participants
    }

    const payload = { ...promo, participantIds: this.participants.map(participant => participant.id) };
    this.licencasService.createPromo(payload)
    .pipe(
      filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe({
      next: (res) => {
        this.alertMessage = "Promoçāo criada com sucesso!";
        this.alertType = 'success';

        this.promo = {};
        this.participants = [];

        this.onSuccess();

        this.activeModal.close();
      },
      error: (res: any) => {
        this.alertMessage = res.error.message;
        this.alertType = 'danger';
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }
}
