import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILicense } from '../model/license.model';
import { ILinkLicenseRequest } from '../model/link-license-request.model';
import { IResponse } from '../model/response.model';
import { ITotalLicenses } from '../model/total-licenses.model';
import { DocumentUser } from '../model/document-user.model';
import { IReplaceLicensesOwnership } from '../model/replace-licenses-ownership-request.model';
import { DocumentPlan } from '../model/document-plan.model';

export interface ICorpPromo {
    idPromo: string;
    namePromo: string;
    description: string;
    emailOwner: string;
    frequency: string;
    quantity: number;
    startDate: string;
    endDate: string;
    active: boolean;
    participants: {
      id: string;
      idUser: string;
      profileImage: string;
      active: boolean;
      name: string;
      email: string;
      quantityCredit: number;
      lastCreditDate: string;
      lastConsumerDate: string;
    }[]
}

export interface IPromosByUser {
  promoName: string;
  promoDescription: string;
  promoId: string;
  quantityCredit: number;
}

@Injectable({
  providedIn: 'root'
})
export class LicencasService {

  baseUrl = environment.profileUrl;
  baseAdmUrl = environment.administradorUrl;

  constructor(
    protected http: HttpClient, protected router: Router
  ) { }

  getLicencas(page: number, size: number): Observable<HttpResponse<ILicense[]>> {
    return this.http.get<ILicense[]>(`${this.baseAdmUrl}/licenses?page=${page}&size=${size}`, { observe: 'response' });
  }

  generateLicenses(numberOfLicenses: number, emailOwner: string | null, emailUser: string | null, emailDestinatarioSemVinculo: string | null, tag: string | null, company: string | null): Observable<HttpResponse<IResponse>> {
    return this.http.post<IResponse>(
      `${this.baseAdmUrl}/generateLicenses`,
      null,
      {
        observe: 'response',
        params: {
          ...(numberOfLicenses && { numberOfLicenses: numberOfLicenses }),
          ...(emailOwner && { emailOwner: emailOwner }),
          ...(emailUser && { emailUsuario: emailUser }),
          ...(emailDestinatarioSemVinculo && { emailDestinatarioSemVinculo: emailDestinatarioSemVinculo }),
          ...(tag && {tag: tag}),
          ...(company && {company: company})
        }
      }
    );
  }

  getTotalLicenses(): Observable<HttpResponse<ITotalLicenses>> {
    return this.http.get<ITotalLicenses>(`${this.baseAdmUrl}/totalLicenses`, { observe: 'response' });
  }

  getCorpConfigs(email: string): Observable<HttpResponse<{ isCorp: boolean; isSluperCoinEnabled: boolean}>> {
    return this.http.get<{ isCorp: boolean; isSluperCoinEnabled: boolean}>(`${this.baseAdmUrl}/corp-configs`, {
      observe: 'response',
      params: {
        email
      }
    });
  }

  getAvailablePlans(): Observable<HttpResponse<DocumentPlan[]>> {
    return this.http.get<DocumentPlan[]>(`${this.baseAdmUrl}/plans`, {
      observe: 'response',
    });
  }

  getLicensesByOwnerEmail(ownerEmail: string): Observable<HttpResponse<ILicense[]>> {
    return this.http.get<ILicense[]>(`${this.baseAdmUrl}/licensesByOwnership`, {
      observe: 'response', params: {
        email: ownerEmail
      }
    });
  }

  unlinkLicense(idLicense: string): Observable<HttpResponse<IResponse>> {
    return this.http.delete<IResponse>(`${this.baseAdmUrl}/unlinkLicense/${idLicense}`, { observe: 'response' });
  }

  linkLicense(linkLicenseRequest: ILinkLicenseRequest): Observable<HttpResponse<IResponse>> {
    return this.http.post<IResponse>(`${this.baseAdmUrl}/linkLicense`, linkLicenseRequest, { observe: 'response' });
  }

  assignLicenseOwner(licenseId: string, ownerEmail: string): Observable<HttpResponse<IResponse>> {
    return this.http.post<IResponse>(`${this.baseAdmUrl}/license/${licenseId}/assign-owner`, {ownerEmail}, { observe: 'response' });
  }

  saveCorpSettings({
    userEmail,
    isCorp,
    isSluperCoinEnabled
  }: {
    userEmail: string;
    isCorp: boolean;
    isSluperCoinEnabled: boolean;
  }): Observable<HttpResponse<IResponse>> {
    return this.http.put<IResponse>(`${this.baseAdmUrl}/corp-settings/${userEmail}`, {
      isCorp,
      sluperCoinEnabled: isSluperCoinEnabled
    }, {
      observe: 'response',
    });
  }

  replaceLicensesOwnership(replaceLicensesOwnership: IReplaceLicensesOwnership): Observable<HttpResponse<IResponse>> {
    return this.http.put<IResponse>(`${this.baseAdmUrl}/replaceLicensesOwnership`, replaceLicensesOwnership, { observe: 'response' });
  }

  activateDeactivateUser(idUser: string): Observable<HttpResponse<IResponse>> {
    return this.http.put<IResponse>(`${this.baseAdmUrl}/user/${idUser}`, null, { observe: 'response' });
  }

  toggleCardGeneratedStatus(licenseId: string): Observable<HttpResponse<IResponse>> {
    return this.http.put<IResponse>(`${this.baseAdmUrl}/user/toggleCardGenerated/${licenseId}`, null, { observe: 'response' });
  }

  resendLicenseEmail(idLicense: string): Observable<HttpResponse<IResponse>> {
    return this.http.post<IResponse>(`${this.baseAdmUrl}/resendLicenseEmail/${idLicense}`, null, { observe: 'response' });
  }

  saveDocumentUser(userProfile: DocumentUser): Observable<HttpResponse<any>> {
    return this.http.put<DocumentUser>(this.baseAdmUrl + "/user", userProfile, { observe: 'response' });
  }

  isEmailInUse(email: string, idUser: string): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(this.baseAdmUrl + "/emailInUse", {
      observe: 'response', params: {
        email: email,
        idUser: idUser
      }
    });
  }

  generateVerificationKey(): Observable<HttpResponse<IResponse>> {
    return this.http.get<IResponse>(this.baseAdmUrl + "/generateVerificationKey", { observe: 'response' });
  }

  getPromosByUser(userId: string): Observable<HttpResponse<IPromosByUser[]>> {
    return this.http.get<IPromosByUser[]>(`${this.baseAdmUrl}/promos-by-user/${userId}`, { observe: 'response' });
  }

  getCorpPromos(): Observable<HttpResponse<ICorpPromo[]>> {
    return this.http.get<ICorpPromo[]>(`${this.baseAdmUrl}/corp-promos`, { observe: 'response' });
  }

  changePromoCreditsAmount(data: { userId: string; promoId: string;  operation: 'DECREASE' | 'INCREASE'}): Observable<HttpResponse<IResponse>> {
    return this.http.patch<IResponse>(`${this.baseAdmUrl}/coins/amount`, data,{ observe: 'response', });
  }

  createPromo(data: {
    promoName?: string;
    promoDescription?: string;
    frequency?: string;
    quantity?: number;
    startDate?: Date;
    endDate?: Date;
    participantIds: string[];
  }): Observable<HttpResponse<IResponse>> {
    return this.http.post<IResponse>(`${this.baseAdmUrl}/corp-promos`, data, { observe: 'response', });
  }

  addPromoParticipants(promoId: string, participantIds: string[]) {
    return this.http.post<IResponse>(`${this.baseAdmUrl}/promo-participants/${promoId}`, {participantIds}, { observe: 'response', })
  }

  removePromoParticipant(sluperCreditId: string): Observable<HttpResponse<IResponse>> {
    return this.http.delete<IResponse>(`${this.baseAdmUrl}/promo-participant/${sluperCreditId}`, { observe: 'response', });
  }

  reactivatePromoParticipant(sluperCreditId: string): Observable<HttpResponse<IResponse>> {
    return this.http.put<IResponse>(`${this.baseAdmUrl}/reactivate-promo-participant/${sluperCreditId}`, {}, { observe: 'response', });
  }
}
