import { CommonModule, JsonPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { NgbAccordionModule, NgbAlertModule, NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { ContasAtivasComponent } from '../contas-ativas/contas-ativas.component';
import { ConfirmaDesvinculoModalComponent } from '../licencas/confirma-desvinculo-modal/confirma-desvinculo-modal.component';
import { ProfilesAccessedRankingModalComponent } from '../dashboard/profiles-accessed-ranking-modal/profiles-accessed-ranking-modal.component';
import { DadosModalComponent } from '../licencas/dados-modal/dados-modal.component';
import { ManageLicensesOwnerModalComponent } from '../licencas/manage-license-owner-modal/manage-license-owner-modal.component';
import { UserSettingsComponent } from '../licencas/manage-license-owner-modal/user-settings/user-settings.component';
import { ReplaceLicensesOwnerComponent } from '../licencas/manage-license-owner-modal/replace-licenses-owner/replace-licenses-owner.component';
import { AssignLicenseOwnerComponent } from '../licencas/manage-license-owner-modal/assign-license-owner/assign-license-owner.component';
import { GerarLicencasModalComponent } from '../licencas/gerar-licencas-modal/gerar-licencas-modal.component';
import { LicencasComponent } from '../licencas/licencas.component';
import { VinculaLicencaModalComponent } from '../licencas/vincula-licenca-modal/vincula-licenca-modal.component';
import { getPortuguesePaginatorIntl } from '../portuguese-paginator/portuguese-paginator-intl';
import { AdmComponent } from './adm.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DashboardCardComponent } from '../dashboard-card/dashboard-card.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BusinessComponent } from '../business/business.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { VinculaLicencaBusinessModalComponent } from '../business/vincula-licenca-business-modal/vincula-licenca-business-modal.component';
import { SluperCoinModalComponent } from '../business/sluper-coin-modal/sluper-coin-modal.component';
import { ConfirmaDesvinculoBusinessModalComponent } from '../business/confirma-desvinculo-business-modal/confirma-desvinculo-business-modal.component';
import { SalesDashboardComponent } from '../sales-dashboard/sales-dashboard.component';
import { IConfig, NgxMaskModule  } from 'ngx-mask';
import { CorpSluperCoinsComponent } from '../corp-sluper-coins/corp-sluper-coins.component';
import { CreatePromoModalComponent } from '../corp-sluper-coins/create-promo/create-promo-modal.component';
import { AddPromoParticipantModalComponent } from '../corp-sluper-coins/add-promo-participant/add-promo-participant-modal.component';
import { BusinessTemplatesComponent } from '../business-templates/business-templates.component';
import { CreateTemplateComponent } from '../business-templates/create-template/create-template.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { ApplicationTemplatesComponent } from '../application-templates/application-templates.component';

const maskConfig: Partial<IConfig> = {
  validation: true, // Isso habilita a validação da máscara
};

@NgModule({
  declarations: [
    AdmComponent,
    ContasAtivasComponent,
    GerarLicencasModalComponent,
    AddPromoParticipantModalComponent,
    VinculaLicencaModalComponent,
    LicencasComponent,
    ConfirmaDesvinculoModalComponent,
    ProfilesAccessedRankingModalComponent,
    SalesDashboardComponent,
    SluperCoinModalComponent,
    CreatePromoModalComponent,
    DashboardComponent,
    DashboardCardComponent,
    DadosModalComponent,
    ManageLicensesOwnerModalComponent,
    CorpSluperCoinsComponent,
    ReplaceLicensesOwnerComponent,
    UserSettingsComponent,
    AssignLicenseOwnerComponent,
    BusinessTemplatesComponent,
    CreateTemplateComponent,
    BusinessComponent,
    VinculaLicencaBusinessModalComponent,
    ConfirmaDesvinculoBusinessModalComponent,
    LoadingSpinnerComponent,
    ApplicationTemplatesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    HttpClientModule,
    ClipboardModule,
    NgbNavModule,
    NgbAccordionModule,
    NgApexchartsModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbAlertModule,
    NgbPopoverModule,
    MatTableModule,
    NgxMaskModule.forRoot(maskConfig),
    MatPaginatorModule,
    NgbDropdownModule,
    MatSortModule,
    JsonPipe,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() }
  ]
})
export class AdmModule { }
