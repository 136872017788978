<div class="base-degade-login">
    <div class="group-1">
        <div class="ellipse-12"></div>
        <div class="ellipse-11"></div>
        <div class="ellipse-9"></div>
    </div>
    <div class="page-content">
        <img src="/assets/img/Simbolo_Brand.png" alt="Simbolo Sluper" *ngIf="screenWidth > 550">
        <div class="login-box">
            <div class="login-box-content">
                <img src="/assets/img/Marca Sluper.png" alt="Marca Sluper" class="box-logo">
                <div style="margin-bottom: 6%; width: 100%;">
                    <div class="text-box">
                        <label for="user" class="label-text" translate>login.email</label>
                        <input type="email" name="user" id="user" class="user-input" [(ngModel)]="userEmail" required
                            #userEmailInput="ngModel">
                    </div>
                    <div
                        *ngIf="(userEmailInput.invalid && (userEmailInput.dirty || userEmailInput.touched)) || emailError">
                        <div *ngIf="userEmailInput.errors?.['required']">Campo obrigatório</div>
                        <div *ngIf="emailError">{{ emailError }}</div>
                    </div>
                </div>

                <div class="enter-button" (click)="openChaveAcessoModal(userEmail)">
                    <div class="apple-button-content">
                        <span class="apple-text" translate>botaoEntrar</span>
                    </div>
                </div>
                <!-- <div class="facebook-button">
                    <div class="facebook-button-content">
                        <img src="/assets/img/Facebook Logo.svg" alt="Facebook logo">
                        <span class="facebook-text">Entrar com Facebook</span>
                    </div>
                </div>
                <div class="google-button">
                    <div class="google-button-content">
                        <img src="/assets/img/Google Logo.svg" alt="Google logo">
                        <span class="google-text">Entrar com Google</span>
                    </div>
                </div>
                <div class="apple-button">
                    <div class="apple-button-content">
                        <img src="/assets/img/Apple Logo.svg" alt="apple logo">
                        <span class="apple-text">Entrar com Apple</span>
                    </div>
                </div> -->
                <!-- <div class="primeiro-acesso-div" (click)="openPrimeiroAcessoModal()">
                    <p class="primeiro-acesso-text">Primeiro Acesso !!!! RETIRAR !!!!</p>
                </div> -->
            </div>
        </div>
    </div>
</div>