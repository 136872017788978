import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginService } from "../login.service";

@Component({
  selector: 'chaveAcesso-modal',
  templateUrl: './chave-acesso-modal.component.html',
  styleUrls: ['./chave-acesso-modal.component.css']
})
export class ChaveAcessoModalComponent {

  @Input() userEmail!: string;
  @Input() screenWidth: any;
  chaveAcesso = new FormControl('', Validators.required);
  chaveValida: string | null = '';
  erroValidacao: string | null = null;

  constructor(
    private activeModal: NgbActiveModal,
    private loginService: LoginService
  ) { }

  onCloseClick() {
    this.activeModal.dismiss(this.erroValidacao);
  }

  validateChaveAcesso() {

  }

  onEntrarClick() {
    this.loginService.validateEmailKey(this.chaveAcesso.value, this.userEmail).subscribe({
      next: (response) => {
        this.chaveValida = response.body?.status === 'SUCCESS' ? response.body?.message : '';
        if (!this.chaveValida) {
          this.activeModal.dismiss(this.erroValidacao);
        } else {
          this.activeModal.close(this.chaveValida);
        }
      },
      error: (err) => {
        console.error("Erro ao validar chave de acesso: ", err);
        this.erroValidacao = err.error?.message || 'Erro ao verificar a chave de acesso';
        this.activeModal.dismiss(this.erroValidacao);
      }
    })
  }
}
