import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NgbAlert, NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { DadosModalComponent } from '../licencas/dados-modal/dados-modal.component';
import { GerarLicencasModalComponent } from '../licencas/gerar-licencas-modal/gerar-licencas-modal.component';
import { LicencasService } from '../licencas/licencas.service';
import { IDocumentLicense } from '../model/document-license.model';
import { IDocumentUser } from '../model/document-user.model';
import { ILicense } from '../model/license.model';
import { IResponse } from '../model/response.model';
import { ITotalLicenses } from '../model/total-licenses.model';
import { ISocialNetwork } from '../model/social-network.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { VinculaLicencaBusinessModalComponent } from './vincula-licenca-business-modal/vincula-licenca-business-modal.component';
import { ConfirmaDesvinculoBusinessModalComponent } from './confirma-desvinculo-business-modal/confirma-desvinculo-business-modal.component';
import { SluperCoinModalComponent } from './sluper-coin-modal/sluper-coin-modal.component';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css'],
  providers: [NgbDropdownConfig]
})
export class BusinessComponent implements OnInit {

  @Input() userProfile!: IUserProfileWithCSS | null;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  nome: string = '';
  linkedLicenses: ILicense[] = [];
  linkedLicensesHistory: ILicense[] = [];
  availableLie: ILicense[] = [];

  length = 10;
  pageSize = 2500;
  pageIndex = 0;
  totalLicencas: number = 0;
  licencasDisponiveis: number = 0;
  linkedLicensesCounter: number = 0;
  loading = false;

  redesSociais: ISocialNetwork[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private licencasService: LicencasService,
    private modalService: NgbModal,
    private config: NgbDropdownConfig
  ) {
    config.placement = 'bottom-end';
    // config.placement = 'left-top';
  }

  ngOnInit(): void {
    if (this.userProfile && ((this.userProfile.documentUser && (this.userProfile.documentUser?.role === 'corp' || this.userProfile.documentUser?.role === 'adm')) || this.userProfile.documentAdmin !== null)) {
      this.getLicencas();
      this.getTotalLicencas();
    }

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  getLicencas() {
    this.loading = true;
    this.licencasService.getLicencas(this.pageIndex + 1, this.pageSize).
      pipe(
        filter((mayBeOk: HttpResponse<ILicense[]>) => mayBeOk.ok),
        map((response: HttpResponse<ILicense[]>) => response.body))
      .subscribe({
        next: (res) => {
          this.setResponse(res);
          this.loading = false;
        },
        error: (res) => {
          console.log(res);
          this.loading = false;
        }
      });
  }

  setResponse(res: ILicense[] | null) {
    if (res) {
      this.linkedLicenses = res.filter(e => e.documentUser !== null);
      this.linkedLicensesHistory = Object.assign([], this.linkedLicenses);
      this.availableLie = res.filter(e => e.documentUser === null);
    }
  }

  getTotalLicencas() {
    this.licencasService.getTotalLicenses().
      pipe(
        filter((mayBeOk: HttpResponse<ITotalLicenses>) => mayBeOk.ok),
        map((response: HttpResponse<ITotalLicenses>) => response.body))
      .subscribe({
        next: (res) => this.setResponseTotalLicencas(res),
        error: (res) => console.log(res)
      });
  }

  setResponseTotalLicencas(res: ITotalLicenses | null) {
    if (res) {
      this.length = res.available + res.used + res.withOwner;

      this.totalLicencas = this.length;
      this.licencasDisponiveis = res.available;
      this.linkedLicensesCounter = Number(this.totalLicencas) - Number(this.licencasDisponiveis);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.userProfile?.documentUser.role == 'adm')
      this.linkedLicenses = this.linkedLicensesHistory.filter(l => l.documentUser.email.toLowerCase().includes(filterValue.toLowerCase()) || l.documentLicense.emailOwner?.toLowerCase().includes(filterValue.toLowerCase()));
    else
      this.linkedLicenses = this.linkedLicensesHistory.filter(l => l.documentUser.email.toLowerCase().includes(filterValue.toLowerCase()));
  }

  openGerarLicencasModal() {
    let modalRef = this.modalService.open(GerarLicencasModalComponent, { centered: true });

    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      }
    }).catch(() => { });
  }

  pageChange() {
    this.pageIndex = this.paginator.pageIndex;
    this.pageSize = this.paginator.pageSize;

    this.getLicencas();
  }

  unlinkLicense(idLicense: string, user: string) {
    const modalRef = this.modalService.open(ConfirmaDesvinculoBusinessModalComponent, { centered: true });
    modalRef.componentInstance.license = idLicense;
    modalRef.componentInstance.user = user;

    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      }
    }).catch(() => { });
  }

  openSluperCoinModal(userId: string) {
    const modalRef = this.modalService.open(SluperCoinModalComponent, { centered: true });
    modalRef.componentInstance.userId = userId;

    modalRef.result.then(result => {
      // if (result) {
      //   this.getLicencas();
      //   this.getTotalLicencas();
      // }
    }).catch(() => { });
  }

  openVinculaLicencaModal() {
    const modalRef = this.modalService.open(VinculaLicencaBusinessModalComponent, { centered: true });
    modalRef.componentInstance.licenses = this.availableLie;

    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      } else {
        this.alertType = 'danger';
        this._alert.next("Ocorreu um erro");
      }
    }).catch(() => { });;
  }

  openDadosModal({ documentUser, documentLicense }: { documentUser: IDocumentUser, documentLicense: IDocumentLicense }) {
    const modalRef = this.modalService.open(DadosModalComponent, { centered: true });

    modalRef.componentInstance.documentLicense = documentLicense;
    modalRef.componentInstance.documentUser = documentUser;
  }

  openLinkLicenca(licenca: string) {
    window.open("https://sluper.bio/" + licenca, "_blank");
  }

  changeActive(idUser: string) {
    this.licencasService.activateDeactivateUser(idUser).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.changeActiveResponse(res),
        error: (res) => console.log(res)
      });
  }

  toggleIsCardGenerated(licenseId: string) {
    this.licencasService.toggleCardGeneratedStatus(licenseId).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.changeActiveResponse(res),
        error: (res) => console.log(res)
      });
  }

  changeActiveResponse(res: IResponse | null) {
    if (res != null && res.status == "SUCCESS") {
    }
  }

  resendLicennse(idLicense: string) {
    this.licencasService.resendLicenseEmail(idLicense).subscribe((res) => {
    });
  }

  copyLicenseToClipboard(license: string): string {
    return "https://sluper.bio/" + license;
  }

  containsSocialNetwork(socialNetwork: string, redes: ISocialNetwork[]): boolean | undefined {
    if (redes) {
      let isPresent = redes.find(e => e.type == socialNetwork);
      if (isPresent) {
        return true;
      } else {
        return false;
      }
    }
    return undefined;
  }

  openSluperLink(link: string) {
    window.open("https://sluper.bio/" + link);
  }

  checkProfileImage(uriProfileImage: string | null) {
    if (uriProfileImage != null) {
      return uriProfileImage;
    } else {
      return '/assets/img/imagem_perfil.png';
    }
  }

  checkBackgroundImage(uriBackgroundImage: string | null) {
    if (uriBackgroundImage != null) {
      return "url(" + uriBackgroundImage + ")";
    } else {
      return 'url(/assets/img/imagem_fundo_preview.png)';
    }
  }
}
