<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="modal-header">
  <span class="title">Adicionar usuário</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCloseClick()"></button>
</div>

<div class="modal-body content">
  <div class="participants-container">
    <div class="participants-heading">
      <span class="participants-title">
        Lista de participantes
      </span>

      <div class="participant-selector-container">
        <div class="input-group inputs">
          <div class="input-group-prepend">
            <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-person-add"></i></span>
          </div>

          <select name="user" id="user" class="form-select input-text" (change)="selectUser($event)">
            <option value="">Adicionar usuário</option>
            <option *ngFor="let option of userOptions" [value]="option.idUser" class="option-item">
              {{ option.name}} - {{ option.email }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="list-container">
      <div *ngIf="participants.length == 0" class="any-users-selected">
        <span class="any-users-selected-text">
          Nenhum usuário selecionado
        </span>
      </div>

      <div class="participant-item" *ngFor="let participant of participants">
        <div class="participant-info">
          <span class="participant-name">{{participant.name}}</span>

          <span class="participant-email">
            {{participant.email}}
          </span>
        </div>

        <button class="remove-participant-button" (click)="removeUser(participant.id)">
          <i class="bi bi-x"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="buttons-container">
    <button class="btn btn-secondary" (click)="onCloseClick()">Cancelar</button>
    <button class="btn btn-primary" (click)="addParticipants()">Salvar</button>
  </div>
</div>

<div class="footer">
</div>