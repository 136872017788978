import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private protectedUrls: string[] = [
    environment.profileUrl,
    environment.segurancaUrl,
    environment.administradorUrl
  ];

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isProtectedUrl(request.url)) {
      return this.handleProtectedRequest(request, next);
    }

    return next.handle(request);
  }

  private isProtectedUrl(url: string): boolean {
    return this.protectedUrls.some(protectedUrl => url.startsWith(protectedUrl));
  }

  private handleProtectedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }
}
