import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbAlert, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { IPromosByUser, LicencasService } from 'src/app/licencas/licencas.service';
import { IResponse } from '../../model/response.model';
import { PromoCredit } from '../corp-sluper-coins.component';

@Component({
  selector: 'app-add-promo-participant-modal',
  templateUrl: './add-promo-participant-modal.component.html',
  styleUrls: ['./add-promo-participant-modal.component.css']
})
export class AddPromoParticipantModalComponent {
  @Input() userId!: string;
  @Input() promoId!: string;
  @Input() currentSelectedUsers!: Array<PromoCredit>;
  @Input() onSuccess!: () => void;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  participants: Array<{ id: string; name: string; email: string }> = [];

  userOptions: { idUser: string; name: string; email: string }[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private dashboardService: DashboardService,
    private licencasService: LicencasService
  ) {
    this.findAllUsers();
  }

  findAllUsers() {
    this.dashboardService.findAllUsersDashboard() .pipe(
      filter((mayBeOk: HttpResponse<{ idUser: string; name: string; email: string }[]>) => mayBeOk.ok),
      map((response: HttpResponse<{ idUser: string; name: string; email: string }[]>) => response.body)
    ).subscribe({
      next: (res) => {
        this.userOptions = res?.filter(x => !this.currentSelectedUsers.find(c => c.idUser === x.idUser)) || [];
      },
      error: (res: any) => console.log(res)
    });
  }

  selectUser(event: any) {
    const userId = event?.target?.value || '';

    if (!userId || userId.length < 5) {
      return;
    }

    const user = this.userOptions.find(option => option.idUser === userId);

    if (user) {
      this.participants.push({
        id: user.idUser,
        name: user.name,
        email: user.email
      });
      this.userOptions = this.userOptions.filter(option => option.idUser !== userId);
    }
  }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  removeUser(userId: string) {
    const participant = this.participants.find(participant => participant.id === userId)

    if (!participant) {
      return;
    }

    this.participants = this.participants.filter(participant => participant.id !== userId);
    this.userOptions.push({
      idUser: participant.id,
      name: participant.name,
      email: participant.email
    });
  }

  addParticipants() {
    this.licencasService.addPromoParticipants(this.promoId, this.participants.map(x => x.id))
    .pipe(
      filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe({
      next: (_res) => {
        this.onSuccess();
        this.activeModal.close();
      },
      error: (res: any) => {
        this.alertMessage = res.error.message;
        this.alertType = 'danger';
      }
    });
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }
}
