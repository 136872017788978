<div class="body-div">
  <section class="header-section">
    <h1 class="title">Sluper Coins</h1>

    <button class="add-new-promo-button" (click)="openCreatePromoModal()">
      Criar promoçāo
    </button>
  </section>

  <section class="promo-list-section">
    <div class="no-promos" *ngIf="corpPromos.length < 1">
      <span class="no-promos-text">Nenhuma promoçāo cadastrada</span>
    </div>

    <div class="promo-item" *ngFor="let promo of corpPromos">
      <div class="promo-row">
        <div class="identifier-container">
          <span class="promo-name">{{promo.namePromo}}</span>
          <span class="promo-description">{{promo.description}}</span>
        </div>

        <div class="promo-infos-container">
          <div class="validity-container">
            <i class="bi bi-calendar-range" style="color: #902ae9; font-size: 14px;"></i>
            <span class="validity-text">de <b>{{promo.startDate | date: 'dd/MM/yyyy'}}</b> até <b>{{promo.endDate |
                date: 'dd/MM/yyyy'}}</b></span>
          </div>

          <div class="frequency-container">
            <i class="bi bi-coin" style="color: #edb53a; font-size: 14px;"></i>
            <span class="frequency-text">{{promo.quantity}} crédito(s)/{{promo.frequency}}</span>
          </div>
        </div>

        <div class="drop-down-trigger-container">
          <button class="drop-down-trigger" (click)="openPromoParticipants(promo.idPromo)">
            <i *ngIf="promo.idPromo !== openedPromoId" class="bi bi-chevron-down"
              style="color: #8b8b8b; font-size: 0.8rem; "></i>
            <i *ngIf="promo.idPromo === openedPromoId" class="bi bi-chevron-up"
              style="color: #8b8b8b; font-size: 0.8rem; "></i>
          </button>
        </div>
      </div>

      <div class="drop-down-container" *ngIf="promo.idPromo === openedPromoId">
        <div class="participants-list-header">
          <span class="participant-list-title">
            Participantes
          </span>

          <div class="participants-actions-container">
            <div class="input-group filter-input-container">
              <div class="input-group-prepend">
                <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-search"></i></span>
              </div>

              <input type="text" class="form-control input-text" id="participantFilter" name="participantFilter"
                placeholder="Filtrar participante" (keyup)="handleParticipantFilterChange($event)">
            </div>

            <button class="add-new-participant-button" matTooltip="Adicionar participante"
              (click)="openAddPromoParticipant(openedPromoParticipants, promo.idPromo)">
              <i class="bi bi-plus"></i>
            </button>
          </div>
        </div>

        <div [ngClass]="participant.active ? 'drop-down-item' : 'drop-down-item disabled-participant'"
          *ngFor="let participant of openedPromoParticipants">
          <div class="user-container">
            <img [src]="participant.profileImage !== null ? participant.profileImage : randomAvatar()"
              alt="Imagem do perfil do participante" class="user-profile-img">

            <div class="user-basic-infos">
              <span class="participant-name">{{participant.name}}</span>
              <span class="participant-mail">{{participant.email}}</span>
            </div>
          </div>

          <div class="participant-actions">
            <div *ngIf="participant.active" class="coins-counter-container">
              <button class="update-coins-button" matTooltip="Remover crédito"
                (click)="handleChangePromoCreditsAmount(participant.idUser, promo.idPromo, 'DECREASE')"
                [disabled]="promo.quantity < 1 || !participant.active">
                <i class="bi bi-dash"></i>
              </button>

              <span class="coins-counter">
                <i class="bi bi-coin promo-coin"></i>
                {{participant.quantityCredit}}
              </span>

              <button class="update-coins-button" [disabled]="!participant.active" matTooltip="Adicionar crédito"
                (click)=" handleChangePromoCreditsAmount(participant.idUser, promo.idPromo, 'INCREASE' )">
                <i class="bi bi-plus"></i>
              </button>
            </div>

            <button *ngIf="participant.active" class="remove-participant-button"
              (click)="removePromoParticipant(participant.id)">
              <i class="bi bi-trash" style="font-size: 12px; color: #939393;"></i>
            </button>

            <button *ngIf="!participant.active" class="add-back-participant-button" matTooltip="Reativar participante"
              (click)="ractivatePromoParticipant(participant.id)">
              <i class="bi bi-person-fill-add" style="font-size: 12px; color: #902ae9;"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>