import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { IDocumentLicense } from 'src/app/model/document-license.model';
import { DocumentPlan } from 'src/app/model/document-plan.model';
import { IDocumentUser } from 'src/app/model/document-user.model';
import { LicencasService } from '../licencas.service';

@Component({
  selector: 'app-dados-modal',
  templateUrl: './dados-modal.component.html',
  styleUrls: ['./dados-modal.component.css']
})
export class DadosModalComponent {
  @Input() documentLicense!: IDocumentLicense;
  @Input() documentUser!: IDocumentUser;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  formattedBirthDateDate = '';
  lastEmail: string = '';
  editEmail: boolean = false;
  formattedPromoPercentage: string | undefined = undefined;
  planExpirationDate = {
    "year": 2024,
    "month": 3,
    "day": 20
  };
  availablePlans: DocumentPlan[] = [];

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private licencasService: LicencasService,
  ) { }

  ngOnInit(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    if(!this.documentUser.plan) {
      this.documentUser.plan = {
        idPlan: '018db261-df67-7a5c-9c91-49777c03220f',
        personalPlan: {},
        createDate: new Date(),
        expirationDate: new Date(),
        updateDate: new Date()
      }
    }

    this.formattedBirthDateDate = this.formatDate();

    this.formattedPromoPercentage = this.documentUser.promoPercentage && this.documentUser.promoPercentage < 10 ? `0${String(this.documentUser.promoPercentage)}` : String(this.documentUser.promoPercentage);

    if (this.formattedPromoPercentage.length <= 2) {
      this.formattedPromoPercentage = `${this.formattedPromoPercentage}.00`;
    }

    if (!this.documentUser.isReseller || !this.documentUser.promoPercentage) {
      this.formattedPromoPercentage = '';
    }

    this.documentUser.plan.expirationDate = new Date(this.documentUser.plan.expirationDate);

    this.planExpirationDate = {
      year: this.documentUser.plan.expirationDate.getFullYear(),
      month: this.documentUser.plan.expirationDate.getMonth() + 1,
      day: this.documentUser.plan.expirationDate.getDate()
    }

    this.licencasService.getAvailablePlans().subscribe({
      next: ({ body }) => {
        if (!body) {
          return;
        }

        this.availablePlans = body;
      },
      error: () => {
        this.alertType = 'danger';
        this._alert.next('Ocorreu um erro ao buscar os planos disponíveis. Contacte o administrador.');
      }
    });
  }

  changePercent(event: any) {
    this.formattedPromoPercentage = event.target.value.replace(',', '.');
  }

  changePlanExpirationDate(event: any) {
    this.planExpirationDate = event;
  }

  formatDate() {
    if (this.documentUser?.personal?.birthdate){
      const formatted = String(this.documentUser.personal.birthdate).split('-');

      if (formatted.length < 3) {
        const defaultDate = "00/00/0000";

        return defaultDate;
      }

      const day = formatted[0].padStart(2, '0');
      const month = formatted[1].padStart(2, '0');
      const year = formatted[2]

      return `${day}/${month}/${year}`;
    }
    return "";
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  editarEmail() {
    this.lastEmail = this.documentUser.email;
    this.editEmail = true;
  }

  onSaveClick() {
    if (this.documentUser.isReseller && !this.documentUser.promoCode) {
      this.alertType = 'danger';
      this._alert.next('Código de revendedor é obrigatório.');
      return;
    }

    if (this.documentUser.isReseller && (!this.formattedPromoPercentage || Number(this.formattedPromoPercentage) < 0.1)) {
      this.alertType = 'danger';
      this._alert.next('Porcentagem da comissāo  é obrigatório.');
      return;
    }

    this.licencasService.isEmailInUse(this.documentUser.email, this.documentUser.idUser).
      pipe(
        filter((mayBeOk: HttpResponse<boolean>) => mayBeOk.ok),
        map((response: HttpResponse<boolean>) => response.body)
      ).subscribe({
        next: (res) => {
          if (!res) {
            this.saveDocumentUser();
          } else {
            this.alertType = 'danger';
            this._alert.next('E-mail informado já está sendo utilizado. Favor informar um e-mail disponível.')
          }
        },
        error: () => {
          this.alertType = 'danger';
          this._alert.next('Ocorreu um erro. Contacte o administrador.');
        }
      });
  }

  handleToggleIsReseller(event: any) {
    if(!event.target.checked) {
      this.documentUser.promoCode = '';
      this.documentUser.promoPercentage = undefined;
      this.formattedPromoPercentage = '';
    }
  }

  saveDocumentUser() {
    this.licencasService.saveDocumentUser({
      ...this.documentUser,
      plan: {
        ...this.documentUser.plan,
        expirationDate: new Date(this.planExpirationDate.year, this.planExpirationDate.month - 1, this.planExpirationDate.day)
      },
      ...(this.documentUser.isReseller && this.formattedPromoPercentage && { promoPercentage: Number(this.formattedPromoPercentage) })
    }).
      pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body))
      .subscribe({
        next: () => {
          this.alertType = 'success';
          this._alert.next('Usuário salvo com sucesso.');
          this.editEmail = false;
          this.documentUser.promoPercentage  = Number(this.formattedPromoPercentage);
        },
        error: () => {
          this.alertType = 'danger';
          this._alert.next('Não foi possível salvar a conta. Contacte o administrador.');
        }
      });
  }
}
