import { Component, Input, OnInit } from '@angular/core';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { ICorpPromo, LicencasService } from '../licencas/licencas.service';
import { filter, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IResponse } from '../model/response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePromoModalComponent } from './create-promo/create-promo-modal.component';
import { AddPromoParticipantModalComponent } from './add-promo-participant/add-promo-participant-modal.component';

export type PromoCredit = { idUser: string; id: string; active: boolean; profileImage: string; name: string; email: string; quantityCredit: number; lastCreditDate: string; lastConsumerDate: string; };

@Component({
  selector: 'app-corp-sluper-coins',
  templateUrl: './corp-sluper-coins.component.html',
  styleUrls: ['./corp-sluper-coins.component.css']
})
export class CorpSluperCoinsComponent implements OnInit {
  @Input() userProfile!: IUserProfileWithCSS | null;

  corpPromos = [] as ICorpPromo[];
  openedPromoId: string = '';
  openedPromoParticipants: PromoCredit[] = [];

  constructor(
    private modalService: NgbModal,
    private licencasService: LicencasService
  ) { }

  ngOnInit(): void {
    this.fetchCorpPromos();
  }

  fetchCorpPromos() {
    this.licencasService.getCorpPromos()
    .subscribe({
      next: (response) => {
        if (!response.body) {
          return;
        }

        this.corpPromos = response.body;

        if (this.openedPromoId.length > 0) {
          const promoParticipants = this.corpPromos.find((promo) => promo.idPromo === this.openedPromoId)?.participants || [];

          this.openedPromoParticipants = promoParticipants;
        }
      },
      error: (_error: any) => {
        console.log(_error);
      }
    });
  }

  randomAvatar() {
    const random = Math.floor(Math.random() * 4) + 1;

    return `/assets/img/default_avatar_${random}.png`;
  }

  openPromoParticipants(promoId: string) {
    if (this.openedPromoId === promoId) {
      this.openedPromoParticipants = [];
      this.openedPromoId = '';

      return;
    }

    const promoParticipants = this.corpPromos.find((promo) => promo.idPromo === promoId)?.participants || [];

    this.openedPromoId = promoId;
    this.openedPromoParticipants = promoParticipants;
  }

  handleParticipantFilterChange(event: any) {
    const searchTerm = event.target.value;

    const promoParticipants = this.corpPromos.find((promo) => promo.idPromo === this.openedPromoId)?.participants || [];

    if (!searchTerm) {
      this.openedPromoParticipants = promoParticipants || [];

      return;
    }

    const filteredParticipants = promoParticipants.filter((participant) => {
      return participant.name.toLowerCase().includes(searchTerm.toLowerCase()) || participant.email.toLowerCase().includes(searchTerm.toLowerCase());
    }) || [];

    this.openedPromoParticipants = filteredParticipants;
  }

  handleChangePromoCreditsAmount(userId: string, promoId: string, operation: 'INCREASE' | 'DECREASE') {
    const payload = { userId: userId, promoId, operation }

    const promoIndex = this.corpPromos.findIndex((promo) => promo.idPromo === promoId);

    this.licencasService.changePromoCreditsAmount(payload)
    .pipe(
      filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe({
      next: (_response) => {
        const participantInde = this.corpPromos[promoIndex].participants.findIndex((participant) => participant.idUser === userId);

        if (operation === 'INCREASE') {
          this.corpPromos[promoIndex].participants[participantInde].quantityCredit += 1;
        } else {
          this.corpPromos[promoIndex].participants[participantInde].quantityCredit -= 1;
        }
      },
      error: (_error: HttpResponse<any>) => {

      }
    });
  }

  removePromoParticipant(sluperCreditId: string) {
    this.licencasService.removePromoParticipant(sluperCreditId)
    .pipe(
      filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe({
      next: (_response) => {
        this.fetchCorpPromos();
      },
      error: (_error: HttpResponse<any>) => {

      }
    });
  }

  ractivatePromoParticipant(sluperCreditId: string) {
    this.licencasService.reactivatePromoParticipant(sluperCreditId)
    .pipe(
      filter((mayBeOk) => mayBeOk.ok),
      map((response: HttpResponse<IResponse>) => response.body)
    ).subscribe({
      next: (_response) => {
        this.fetchCorpPromos();
      },
      error: (_error: HttpResponse<any>) => {
        console.log('_error');
      }
    });
  }

  openCreatePromoModal() {
    const modalRef = this.modalService.open(CreatePromoModalComponent, { centered: true, size: 'lg' });

    modalRef.componentInstance.onSuccess = () => this.fetchCorpPromos();

    modalRef.result.then(result => {
      if (result) {
        this.fetchCorpPromos();
      }
    }).catch(() => { });
  }

  openAddPromoParticipant(openedPromoParticipants: PromoCredit[], promoId: string) {
    const modalRef = this.modalService.open(AddPromoParticipantModalComponent, { centered: true, size: 'lg' });

    modalRef.componentInstance.onSuccess = () => this.fetchCorpPromos();
    modalRef.componentInstance.promoId = promoId;
    modalRef.componentInstance.currentSelectedUsers = openedPromoParticipants;

    modalRef.result.then(result => {
      if (result) {
        this.fetchCorpPromos();
      }
    }).catch(() => { });
  }
}
