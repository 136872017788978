import { Component, Input } from '@angular/core';
import { LISTA_REDES } from '../model/redes';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css'],
})
export class DashboardCardComponent {
  @Input() dashboardCardData: {
    value?: string;
    title: string;
    color: string;
    icon: string;
    links?: {
      url: string;
      count: number;
    }[];
    names?: string[];
  } = {
    value: '',
    title: '',
    color: '',
    icon: '',
  };
  @Input() variant: 'default' | 'list-links' | 'list-name' | 'list-social-networks' = 'default';
  @Input() canOpenList = false;
  linkListOpened = false;

  getSocialNetworkIconByUrl(url: string) {
    let socialIcon = '';

    LISTA_REDES.forEach((social) => {
      if (url.includes(social.value)) {
        socialIcon = social.icon;
      }
    });

    return socialIcon;
  }

  toggleLinkList() {
    this.linkListOpened = !this.linkListOpened;
  }
}
