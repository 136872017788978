export const LISTA_FONTES = [
    'Arial',
    'AvertaStd-Black',
    'AvertaStd-Light',
    'Brush Script MT',
    'Comic Sans MS',
    'Constantia',
    'Courier New',
    'Georgia',
    'Helvetica',
    'Impact',
    'Inter',
    'Lucida Sans',
    'Roboto',
    'SF Pro Display',
    'Trebuchet MS',
    'Verdana',
    'Wix Madefor Text'
]